import React, {ReactElement} from 'react'
import {DraggableProvided} from 'react-beautiful-dnd'
import styled from 'styled-components'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {SectionConfigType} from 'TemplateEditor/Layout/Sections/SectionConfig'
import {
  newSection1,
  PreviewSection1,
  Section1,
  section1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {
  newSection111,
  PreviewSection111,
  Section111,
  section111TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section111'
import {
  newSection1111,
  PreviewSection1111,
  Section1111,
  section1111TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1111'
import {
  newSection112,
  PreviewSection112,
  Section112,
  section112TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section112'
import {
  newSection1by1,
  PreviewSection1by1,
  Section1by1,
  section1by1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1by1'
import {
  newSection1by2,
  PreviewSection1by2,
  Section1by2,
  section1by2TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section1by2'
import {
  newSection211,
  PreviewSection211,
  Section211,
  section211TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section211'
import {
  newSection2by1,
  PreviewSection2by1,
  Section2by1,
  section2by1TypeId,
} from 'TemplateEditor/Layout/Sections/Types/Section2by1'
import {Section as SectionData} from 'TemplateEditor/template'

export type SectionProps = {
  section: SectionData
  sectionIndex: number
  key: number
}

export type ListSectionType = {
  typeId: string
  component: (props: any) => ReactElement
  preview: () => ReactElement
  new: () => SectionData
}

export const ListSections: ListSectionType[] = [
  {
    typeId: section1TypeId,
    component: Section1,
    preview: PreviewSection1,
    new: newSection1,
  },
  {
    typeId: section1by1TypeId,
    component: Section1by1,
    preview: PreviewSection1by1,
    new: newSection1by1,
  },
  {
    typeId: section1by2TypeId,
    component: Section1by2,
    preview: PreviewSection1by2,
    new: newSection1by2,
  },
  {
    typeId: section2by1TypeId,
    component: Section2by1,
    preview: PreviewSection2by1,
    new: newSection2by1,
  },
  {
    typeId: section111TypeId,
    component: Section111,
    preview: PreviewSection111,
    new: newSection111,
  },
  {
    typeId: section112TypeId,
    component: Section112,
    preview: PreviewSection112,
    new: newSection112,
  },
  {
    typeId: section211TypeId,
    component: Section211,
    preview: PreviewSection211,
    new: newSection211,
  },
  {
    typeId: section1111TypeId,
    component: Section1111,
    preview: PreviewSection1111,
    new: newSection1111,
  },
]

export const defaultSectionConfig = (): SectionConfigType => {
  return {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    border: {
      color: '#ffffff',
      width: 0,
      radius: 0,
    },
    background: '#ffffff',
    backgroundImage: null,
    revertMobile: false,
    visibility: 'all',
    visible: true,
  }
}

export function Section(props: {
  section: SectionData
  sectionIndex: number
  provided?: DraggableProvided
}) {
  const {section, sectionIndex, provided} = props
  const {editMode, setEditElement} = useEditor()

  const handleSetEditMode = () => {
    if (!editMode) return

    setEditElement(`sections.${sectionIndex}`)
  }

  if (!editMode && !section.visible) return null

  return (
    <SectionHolder
      {...provided?.draggableProps}
      {...provided?.dragHandleProps}
      ref={provided?.innerRef}
      $editMode={editMode}
      $section={section}
    >
      {editMode && (
        <EditButton onClick={handleSetEditMode}>
          <i className="fa-light fa-edit"></i>
        </EditButton>
      )}

      {ListSections.map((listSection) => {
        if (section.type !== listSection.typeId) return null

        return listSection.component({
          sectionIndex: sectionIndex,
          section: section,
          key: sectionIndex,
        })
      })}
    </SectionHolder>
  )
}

const EditButton = styled.div`
  position: absolute;
  background: #f7496d;
  padding: 0px 10px;
  color: white;
  display: none;
  cursor: pointer;
  right: 0;
  font-size: 18px;
  border-bottom-left-radius: 4px;
  z-index: 20;
`

const SectionHolder = styled.div<{
  $editMode: boolean
  $section: SectionData
}>`
  border: ${(props) => (props.$editMode ? '1px grey dashed' : '')};

  ${(props) => (!props.$section.visible ? 'border: 4px #f7496d solid' : '')};

  ${(props) =>
    props.$section.visibility === 'desktop'
      ? '@container (max-width: 991px) { display: none;}'
      : ''};
  ${(props) =>
    props.$section.visibility === 'mobile'
      ? '@container (min-width: 991px) { display: none;}'
      : ''};

  &:hover ${EditButton} {
    display: inline;
  }
`
