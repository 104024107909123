import React from 'react'
import {InputGroup} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import {Position} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function PositionInput(props: {
  value: Position
  setFieldValue: (field: string, value: Position) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const setValues = (
    newVValue: Position['vertical'],
    newHValue: Position['horizontal'],
  ) => {
    setFieldValue(name, {...value, vertical: newVValue, horizontal: newHValue})
  }

  function isChecked(v: Position['vertical'], h?: Position['horizontal']) {
    if (v === value.vertical && h === value.horizontal) return true // normal ones
    if (!h && v === value.vertical) return true // spacing ones

    if (
      value.vertical === 'space-around' ||
      value.vertical === 'space-between' ||
      value.vertical === 'space-evenly'
    ) {
      if (v === 'center' && h === value.horizontal) return true
    }

    return false
  }

  return (
    <>
      <Form.Label>{props.label}</Form.Label>
      <div className="row m-0">
        <div className="col-6">
          <StyledFormGroup className="mb-3 pb-2">
            <StyledInputGroup>
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('start', 'start')}
                onChange={() => {
                  setValues('start', 'start')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('start', 'center')}
                onChange={() => {
                  setValues('start', 'center')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('start', 'end')}
                onChange={() => {
                  setValues('start', 'end')
                }}
              />
            </StyledInputGroup>

            <StyledInputGroup>
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('center', 'start')}
                onChange={() => {
                  setValues('center', 'start')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('center', 'center')}
                onChange={() => {
                  setValues('center', 'center')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('center', 'end')}
                onChange={() => {
                  setValues('center', 'end')
                }}
              />
            </StyledInputGroup>

            <StyledInputGroup>
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('end', 'start')}
                onChange={() => {
                  setValues('end', 'start')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('end', 'center')}
                onChange={() => {
                  setValues('end', 'center')
                }}
              />
              <Form.Check
                name={name}
                type="radio"
                checked={isChecked('end', 'end')}
                onChange={() => {
                  setValues('end', 'end')
                }}
              />
            </StyledInputGroup>
          </StyledFormGroup>
        </div>
        <div className="col-6">
          <StyledFormGroup className="mb-3 pb-2">
            <StyledInputGroup>
              <Form.Check
                name={name + 'spacing'}
                type="radio"
                checked={isChecked('space-between')}
                onChange={() => {
                  setValues('space-between', value.horizontal)
                }}
              />
            </StyledInputGroup>

            <StyledInputGroup>
              <Form.Check
                name={name + 'spacing'}
                type="radio"
                checked={isChecked('space-evenly')}
                onChange={() => {
                  setValues('space-evenly', value.horizontal)
                }}
              />
            </StyledInputGroup>

            <StyledInputGroup>
              <Form.Check
                name={name + 'spacing'}
                type="radio"
                checked={isChecked('space-around')}
                onChange={() => {
                  setValues('space-around', value.horizontal)
                }}
              />
            </StyledInputGroup>
          </StyledFormGroup>
        </div>
      </div>
    </>
  )
}

const StyledInputGroup = styled(InputGroup)`
  display: flex;
  gap: 1rem;
  justify-content: center;
`

const StyledFormGroup = styled(Form.Group)``
