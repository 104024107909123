import overlay_img from 'images/loginb2.webp'
import {useFormik} from 'formik'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {Link, useNavigate} from 'react-router-dom'
import {login_schema} from 'utils/validation-schema'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {routes} from 'routes/routes'
import {LoginData} from 'attendee/Auth/Interfaces/login'
import GoogleIcon from 'assets/icons/svg/GoogleIcon'

const ErrorMsg = ({errors}: any) => {
  return (
    <>
      <p className="error-msg" style={{color: 'red'}}>
        {errors}
      </p>
    </>
  )
}

export function Login() {
  const [isBtnDisable, setBtnDisable] = useState(false)

  const navigate = useNavigate()
  const {login, user, googleAuth} = useAuth()

  useEffect(() => {
    if (user) {
      navigate(routes.index)
    }
  }, [navigate, user])

  const {handleSubmit, handleBlur, handleChange, values, errors, touched} =
    useFormik({
      initialValues: {
        email: '',
        password: '',
        remember: false,
      },
      validationSchema: login_schema,
      onSubmit: async (values: LoginData) => {
        setBtnDisable(true)
        const loginResult = await login(values)

        if (loginResult === true) {
          toast.success('Успешен вход')
          navigate(routes.index)
        }

        setBtnDisable(false)
      },
    })

  const googleLogin = () => {
    googleAuth()
  }

  return (
    <>
      <main className={`body-area bd-theme-light`}>
        <section className="signin__area">
          <div className="sign__main-wrapper">
            <div className="sign__left">
              <div className="sign__header">
                <div className="sign__logo">
                  <Link to="/">
                    <img
                      style={{width: '150px', height: 'auto'}}
                      className="logo-black"
                      src={process.env.PUBLIC_URL + '/logo-dark.svg'}
                      alt="logoblack"
                    />
                  </Link>
                </div>
                <div className="sign__link custome_link">
                  <Link className="sign__link-active" to={routes.login}>
                    Вход
                  </Link>
                  <Link className="sign__link-text" to={routes.register}>
                    Регистрация
                  </Link>
                </div>
              </div>

              <div
                className="mb-35 mt-35"
                style={{display: 'flex', gap: 15, alignItems: 'center'}}
              >
                <p style={{lineHeight: '1.2rem', margin: 0}}>
                  За да <b>създадете събитие</b> е нужно да се регистрирате като
                  организация от тук:
                </p>
                <Link
                  to={routes.organization.login}
                  className={'input__btn w-100'}
                  type="submit"
                  style={{textAlign: 'center'}}
                >
                  Вход за организации
                </Link>
              </div>

              <div className="sign__center-wrapper text-center mt-45">
                <div className="sign__title-wrapper mb-40">
                  <h3 className="sign__title">Добре дошли</h3>
                  <p>Вход за участници</p>
                </div>
                <div className="sign__input-form text-center">
                  <form onSubmit={handleSubmit}>
                    <div className="sign__input mb-32">
                      <input
                        type="text"
                        placeholder="Въведете вашият Имейл адрес"
                        name="email"
                        defaultValue={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <span>
                        {' '}
                        <i className="flaticon-user-2"></i>
                      </span>{' '}
                      <br />
                      {touched.email && <ErrorMsg errors={errors.email} />}
                    </div>

                    <div className="sign__input mb-32">
                      <input
                        type="password"
                        placeholder="Парола"
                        name="password"
                        defaultValue={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        required
                      />
                      <span>
                        <i className="flaticon-password"></i>
                      </span>
                      {touched.password && (
                        <ErrorMsg errors={errors.password} />
                      )}
                    </div>
                    <div className="sign__action">
                      <div className="sign__check">
                        <input
                          className="e-check-input"
                          type="checkbox"
                          name="remember"
                          id="register-user"
                        />
                        <label
                          className="sign__check-text"
                          htmlFor="register-user"
                        >
                          Запомни ме
                        </label>
                      </div>
                      <div className="sign__forget">
                        <span>
                          <Link to={routes.passwordRecover}>
                            Забравена парола?
                          </Link>
                        </span>
                      </div>
                    </div>
                    <div className="sing__button mb-20">
                      <button
                        disabled={isBtnDisable}
                        className={
                          isBtnDisable
                            ? 'disableBtnStyle w-100 mb-20'
                            : 'input__btn w-100 mb-20'
                        }
                        type="submit"
                      >
                        Вход
                      </button>
                    </div>
                  </form>

                  <button
                    className="gamil__sign-btn w-100"
                    onClick={googleLogin}
                  >
                    <span>
                      <GoogleIcon />
                    </span>{' '}
                    Вход с Google
                  </button>

                  <div className="if__account mt-85">
                    <p>
                      Нямаш профил?
                      <Link to={routes.register}>
                        <br /> Регистрация
                      </Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign__right">
              <div
                className="sign__input-thumb"
                style={{
                  backgroundImage: `url(${overlay_img})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              ></div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
