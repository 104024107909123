import Page from 'attendee/theme/Page'
import React from 'react'
import styled from 'styled-components'
import AssistantIcon from '@mui/icons-material/Assistant'
import SettingsRemoteIcon from '@mui/icons-material/SettingsRemote'
import InterpreterModeIcon from '@mui/icons-material/InterpreterMode'
import WebIcon from '@mui/icons-material/Web'
import PointOfSaleIcon from '@mui/icons-material/PointOfSale'
import SmsIcon from '@mui/icons-material/Sms'
import AccountBalanceIcon from '@mui/icons-material/AccountBalance'
import TrendingDownIcon from '@mui/icons-material/TrendingDown'
import {HelpButton, HelpButtonHolder, HelpText} from 'attendee'
import {routes} from 'routes/routes'
import CorporateFareIcon from '@mui/icons-material/CorporateFare'
import CounterShape from 'assets/img/shape/counter-shape.png'
import {WhyOnlineEvent} from 'attendee/theme/WhyOnlineEvent'
import hometalker from 'assets/custom_images/hometalker.png'

export function Info() {
  return (
    <Page>
      <>
        <section className="contact_section dark_light_class pt-55 pb-55">
          <div className="container">
            <div className="section__title-wrapper mb-80">
              <span className="section__back-title">КЕ</span>
              <span className="section__subtitle">Информация</span>
              <h1 className="section__title">
                Какво е <span className="text__highlight">Eventa.tv</span>
              </h1>
            </div>
            <StyledBlockquote
              cite="/blog"
              style={{backgroundImage: `url(${hometalker})`}}
            >
              <h3 className="blockquote_content mb-0">
                Eventa.tv е платформа с която можете да създадете и управлявате
                онлайн събитие с няколко клика.
                <br />
                Насочена към малкия и среден бизнес и проектирана да бъде
                интуитивна и лесна за използване както за организаторите така и
                за участниците.
              </h3>
            </StyledBlockquote>

            <div className="row ">
              <InfoItem className="col-12">
                <div>
                  <InfoItemHeader>
                    Автоматично създаване и организиране на стаи за участниците
                  </InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <AssistantIcon />
                    </InfoItemIcon>
                    Организаторите могат да спестят време и усилия, чрез
                    автоматичната настройка и разпределение на участниците.
                    Платформата също така предлага възможности за
                    персонализация, която позволява на организаторите да задават
                    специфични правила и настройки за всяка стая.
                  </InfoItemContent>
                </div>
              </InfoItem>
              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>
                    Възможност на участниците да предават звук и видео по време
                    на събитието
                  </InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <SettingsRemoteIcon />
                    </InfoItemIcon>
                    Организаторите могат да създадат интерактивна среда, където
                    участниците задават въпроси, споделят мнения и участват в
                    дискусии чрез микрофони и камери. Това подобрява
                    комуникацията и ангажираността. Платформата позволява на
                    организаторите да контролират кой може да говори и кога.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>
                    Максимален брой на участници до 50 000
                  </InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <InterpreterModeIcon />
                    </InfoItemIcon>
                    Малкия и среден бизнес може да достигне до широка аудитория,
                    без значителни инвестиции в скъпи физически пространства или
                    оборудване. Това прави онлайн събитията достъпни и
                    ефективни, дори при капацитет от 50 000 участници,
                    осигурявайки гладко протичане.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-12">
                <div>
                  <InfoItemHeader>
                    Конструктор на собствена страница
                  </InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <WebIcon />
                    </InfoItemIcon>
                    Създаване и управление на собствена страница, от която
                    участниците могат да се присъединят към събитието. С помощта
                    на интуитивния интерфейс и готови шаблони, организаторите
                    могат да персонализират страницата според нуждите си,
                    осигурявайки лесен достъп и удобство за всички участници.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>Модул за продажба на билети</InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <PointOfSaleIcon />
                    </InfoItemIcon>
                    С този модул организаторите могат да се фокусират върху
                    съдържанието на своите събития, докато платформата се грижи
                    за техническите аспекти на продажбата на билети и
                    управлението на участниците, осигурявайки следене на
                    продажбите в реално време и удобни начини на плащане.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>Лесна комуникация с участници</InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <SmsIcon />
                    </InfoItemIcon>
                    Изпращане на покани и напомняния чрез Имейл и СМС до всички
                    регистрирани участници, което гарантира, че никой няма да
                    пропусне важна информация. Организаторите могат директно да
                    изпращат имейл към регистираните участниците при нужда,
                    осигурявайки допълнителна гъвкавост и сигурност.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>
                    Прозрачно отчитане на печалбата
                  </InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <AccountBalanceIcon />
                    </InfoItemIcon>
                    Отчети, които включват информация за броя на продадените
                    билети, събраните такси и извършените плащания. Тези отчети
                    са достъпни в реално време и могат да бъдат изтеглени във
                    формат, удобен за счетоводни нужди. Това улеснява
                    проследяването на финансовите потоци и осигурява пълна
                    прозрачност за бизнеса.
                  </InfoItemContent>
                </div>
              </InfoItem>

              <InfoItem className="col-xxl-6 col-xl-6 col-lg-6">
                <div>
                  <InfoItemHeader>Минимална комисионна</InfoItemHeader>
                  <InfoItemContent>
                    <InfoItemIcon>
                      <TrendingDownIcon />
                    </InfoItemIcon>
                    Особено полезно за малки и средни предприятия с ограничен
                    бюджет, които искат да достигнат до широка аудитория и да
                    проведат професионални онлайн събития. Минималната
                    комисионна позволява инвестиции в съдържание, маркетинг или
                    техническа поддръжка, повишавайки качеството и привличайки
                    повече участници.
                  </InfoItemContent>
                </div>
              </InfoItem>
            </div>

            <div className="section__title-wrapper mt-80 mb-80">
              <span className="section__back-title">ПР</span>
              <span className="section__subtitle">Информация</span>
              <h2 className="section__title">
                Примерни{' '}
                <span className="text__highlight">ПРИХОДИ И РАЗХОДИ</span>
              </h2>
            </div>

            <div>
              <ProfitHolder className="row">
                <ProfitHeader className="col-12">
                  <div>
                    Пример за приходи и разходи за събитие с цена на билет 20
                    лв. и 500 участника:{' '}
                  </div>
                </ProfitHeader>

                <Income className="col-xxl-6 col-xl-6 col-lg-6">
                  <div>
                    <b>Приходи:</b> <br />
                    Приходи от продажба на билети: 20 лв. x 500 = 10 000 лв.{' '}
                    <br />
                    Приходи от външни продажби: -
                  </div>
                </Income>

                <Expenses className="col-xxl-6 col-xl-6 col-lg-6">
                  <div>
                    {' '}
                    <b>Разходи:</b> <br />
                    Разходи за създаване на събитието: 179 лв. <br />
                    Разходи за такси: около 2 500 лв. <br />
                  </div>
                </Expenses>

                <Profit className="col-12">
                  <div>
                    <b>Печалба:</b> 7 321 лв. + продажби от външни източници
                  </div>
                </Profit>
              </ProfitHolder>
            </div>
          </div>
        </section>

        <section className="counter__area include__bg pt-150 pb-120 counter__overlay fix">
          <div className="counter__shape">
            <img
              src={CounterShape}
              style={{width: 'auto', height: 'auto'}}
              alt="image not found"
            />
          </div>
          <div className="container">
            <div className="row" style={{alignItems: 'center'}}>
              <HelpText>Искаш собствено събитие?</HelpText>

              <HelpButtonHolder>
                <HelpButton
                  className="input__btn"
                  to={routes.organization.login}
                >
                  <CorporateFareIcon /> <span>Регистрация на организация</span>
                </HelpButton>
              </HelpButtonHolder>
            </div>
          </div>
        </section>

        <WhyOnlineEvent />
      </>
    </Page>
  )
}

const ProfitHolder = styled.div``

const ProfitHeader = styled.div`
  & > div {
    font-weight: bold;
    background: var(--clr-gradient-14);
    padding: 15px;
    border-radius: 5px;
    color: #ffffff;
    padding: 15px;
  }
`

const Profit = styled.div`
  & > div {
    background: #f5f5f5;
    padding: 15px;
    border-radius: 5px;
  }

  margin-top: 15px;
`

const Income = styled.div`
  & > div {
    background: #f5f5f5;
    padding: 15px;
    min-height: 110px;
    border-radius: 5px;
  }

  margin-top: 15px;
`

const Expenses = styled.div`
  & > div {
    background: #f5f5f5;
    padding: 15px;
    min-height: 110px;
    border-radius: 5px;
  }

  margin-top: 15px;
`

const InfoItem = styled.div`
  padding-top: 10px;
  padding-bottom: 10px;
  & > div {
    border-radius: 5px;
    background: #f5f5f5;
  }
`

const InfoItemHeader = styled.h4`
  font-weight: bold;
  background: var(--clr-gradient-14);
  padding: 15px;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  color: white;
  font-size: 1rem;
`

const InfoItemContent = styled.div`
  padding: 15px;
  display: flex;
  align-items: center;
  min-height: 165px;
`

const InfoItemIcon = styled.div`
  padding: 0px 15px;

  & > svg {
    font-size: 4rem;
    color: var(--clr-common-black);
  }
`

const StyledBlockquote = styled.blockquote`
  background-size: cover;
  background-position: left center;

  & > h3 {
    text-align: center;
    color: #ffffff;
  }
`
