import React from 'react'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import DashboardPageWithoutSideBar from 'organization/theme/DashboardPageWithoutSideBar'
import {useOrganizationBilling} from 'organization/Billing/Providers/OrganizationBillingProvider'
import useNewEventPayment from 'organization/Billing/Payments/useNewEventPayment'
import styled from 'styled-components'
import {useFormik} from 'formik'
import {handleAddressChange} from 'organization/Event/Settings'

export const CreateEvent = () => {
  const {payPayment} = useOrganizationBilling()
  const {payment} = useNewEventPayment()

  const {handleSubmit, handleBlur, handleChange, values} = useFormik({
    initialValues: {
      name: '',
      slug: '',
      is_free: null,
    },
    onSubmit: async (values: {
      name: string
      slug: string
      is_free: boolean | null
    }) => {
      if (values.is_free === null) return

      payPayment(payment(values))
    },
  })

  return (
    <DashboardPageWithoutSideBar>
      <>
        <BreadcrumMenu title="Създаване на събитие" />

        <div className="row">
          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                {/* create event form  */}
                <form onSubmit={handleSubmit}>
                  <div className="create__event-main pt-25">
                    <div className="event__left-box">
                      <div className="create__input-wrapper">
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Име на събитието <br />
                            <small>
                              <i>(може да бъде променето по-късно)</i>
                            </small>
                          </label>
                          <input
                            type="text"
                            name="name"
                            placeholder="Въведете име ..."
                            defaultValue={values.name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Адрес на събитието -{' '}
                            <AddressShow>{`www.eventa.tv/${values.slug}`}</AddressShow>{' '}
                            <br />
                            <small>
                              <i>(може да бъде променето по-късно)</i>
                            </small>
                          </label>
                          <InputGroup>
                            <span>www.eventa.tv/</span>
                            <input
                              type="text"
                              name="slug"
                              placeholder="Въведете адрес ..."
                              value={values.slug}
                              onChange={(e) => {
                                handleAddressChange(e, handleChange)
                              }}
                              onBlur={handleBlur}
                              required
                            />
                          </InputGroup>
                        </div>
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Тип на събитието
                            <br />
                            <small>
                              <i>
                                (<b>НЕ</b> може да бъде променето по-късно)
                              </i>
                            </small>
                          </label>
                          <label className="radio-group">
                            <input
                              type="radio"
                              value="false"
                              name="is_free"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <p className="info">
                              <span className="name">Платено</span>
                              1. Участниците трябва да закупят билет за да се
                              регистрират. <br />
                              2. Цената на билета се определя от вас. <br />
                              3. Вие получавате печалбата от билетите.
                            </p>
                          </label>

                          <label className="radio-group">
                            <input
                              type="radio"
                              value="true"
                              name="is_free"
                              onChange={handleChange}
                              onBlur={handleBlur}
                              required
                            />
                            <p className="info">
                              <span className="name">Безплатно</span>
                              1. Участниците могат да се регистират безплатно за
                              вашето събитие. <br />
                              2. Получавате 100 безплатни места. <br />
                              3. Ако желаете повече от 100 места, трябва да
                              бъдат заплатени от вас.
                            </p>
                          </label>
                        </div>
                      </div>
                    </div>
                    <div className="event__right-box">
                      <PromoHolder>
                        <b>Промоционална цена за нашите първи 50 събития!</b>{' '}
                        <br />
                        Бъдете сред първите докоснали се до предимствата на{' '}
                        <br /> онлайн събитията!
                      </PromoHolder>

                      <div className="pricing__item mb-30 mt-15">
                        <div className="pricing__item-header">
                          <div>
                            <span className="pricing__item-title">
                              Цена: <OldPrice>179</OldPrice>
                              <NewPrice>1</NewPrice> лв.
                            </span>
                          </div>
                        </div>
                        <div className="pricing__item-content">
                          <ul>
                            <li>
                              <i className="flaticon-comment"></i> Собстена
                              страница
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Конструктор
                              на страницата
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Модул за
                              продажба на билети
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Автоматични
                              известия на участниците
                            </li>
                            <li>
                              <i className="flaticon-comment"></i> Хостинг на
                              събитието
                            </li>
                          </ul>
                        </div>
                        <div className="pricing__btn-wrap">
                          <button className="input__btn w-100" type="submit">
                            Създай събитие
                          </button>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </DashboardPageWithoutSideBar>
  )
}

const OldPrice = styled.div`
  display: inline-block;
  color: #ff000087;
  text-decoration: none;
  position: relative;
  &:before {
    content: ' ';
    display: block;
    width: 128%;
    border-top: 4px solid #ff0000c9;
    height: 25px;
    position: absolute;
    bottom: 0;
    left: -2px;
    transform: rotate(-25deg);
  }
`

const NewPrice = styled.div`
  display: inline-block;
  margin-left: 15px;
`

const PromoHolder = styled.div`
  background: #fff6f9;
  padding: 15px;
  text-align: center;
`

const InputGroup = styled.div`
  display: flex;

  & > span {
    display: flex;
    justify-content: center;
    align-items: center;
    padding: 0px 15px;
    border: 1px solid var(--clr-border-4);
    border-radius: 4px;
    border-right: none;
  }
`
const AddressShow = styled.span`
  color: blue;
  cursor: pointer;
`
