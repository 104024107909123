import {organizationApiClient} from 'organization/organizationApiClient'
import {useOrganizationEventRoutes} from 'organization/OrganizationRoutes'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React from 'react'
import {useEffect} from 'react'
import {useLocation, useNavigate} from 'react-router'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export default function StripeConnectedCallbackHandler() {
  const location = useLocation()
  const navigate = useNavigate()
  const stripeAccountId = location.pathname.split('/')[7]

  const apiClient = organizationApiClient()
  const eventId = useRouteOrganizationEventId()
  const routes = useOrganizationEventRoutes()

  useEffect(() => {
    apiClient
      .post(`api/event/${eventId}/stripe/${stripeAccountId}/connected`)
      .finally(() => {
        navigate(routes.earning.root)
      })
  }, [])

  return <FullPageLoader />
}
