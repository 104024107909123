import {useFormik} from 'formik'
import {useEventAttendants} from 'organization/Event/Attendants/EventAttendantsProvider'
import React from 'react'
import {toast} from 'react-toastify'

export const AddModal = (props: {show: boolean; toggle: () => void}) => {
  const {show, toggle} = props

  const {addAttendant, loading} = useEventAttendants()

  const {handleSubmit, handleChange, values} = useFormik({
    initialValues: {
      email: '',
    },
    onSubmit: async (values: {email: string}) => {
      const result = await addAttendant(values.email)

      if (result === true) {
        toast.success('Успешно добавен участник')
        toggle()
      }
    },
  })

  return (
    <>
      <div
        onClick={toggle}
        className={`offcanvas__overlay ${show ? 'overlay-open' : ''}`}
      ></div>
      <section className={`speaker__popup-area ${show ? 'open' : ''}`}>
        <div className="popup__wrapper">
          <div className="popup__title-wrapper">
            <h3 className="popup__title">Добавяне на участник</h3>
          </div>
          <div className="popup__input-wrapper">
            <form onSubmit={handleSubmit}>
              <div className="singel__input-field mb-15">
                <label className="input__field-email">
                  Имейл на участника <br />
                  <span style={{fontSize: '0.8rem'}}>
                    Забележка: Участникът трябва да бъде предварително
                    регистриран в Eventa.tv
                  </span>
                </label>
                <input
                  type="email"
                  name="email"
                  defaultValue={values.email}
                  onChange={handleChange}
                  required
                />
              </div>

              <button
                type="submit"
                disabled={loading}
                className={
                  loading ? 'disableBtnStyle w-100' : 'input__btn w-100'
                }
              >
                <i className="fa-regular fa-plus"></i>Добави участник
              </button>
            </form>
          </div>
        </div>
      </section>
    </>
  )
}
