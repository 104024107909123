import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import YouTubeIcon from '@mui/icons-material/YouTube'
import ReactPlayer from 'react-player/lazy'
import styled from 'styled-components'

export const VideoTypeId = 'block-video'

export function Video(props: {
  block: VideoConfigType
  section: Section
  setEditMode: () => void
  key: string | number
  editMode: boolean
}) {
  const {block, setEditMode, editMode} = props

  const NoVideo = () => {
    return (
      <NoVideoHolder block={block}>
        <PreviewVideo />
      </NoVideoHolder>
    )
  }

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <StyledVideo block={block}>
        {block.url && editMode && <NoVideo />}
        {block.url && !editMode && (
          <ReactPlayer controls={block.controls} url={block.url} />
        )}
        {!block.url && <NoVideo />}

        {/* <ReactPlayer controls={block.controls} url={block.url} /> */}
      </StyledVideo>
    </DefaultBlockHolder>
  )
}

export function PreviewVideo() {
  return (
    <PreviewHolderDiv>
      <YouTubeIcon />
      <span>Видео</span>
    </PreviewHolderDiv>
  )
}

export type VideoConfigType = Block &
  DefaultBlockConfigType & {
    url: string
    controls: boolean
    width: number
    height: number
    mobile: {
      width: number
      height: number
    }
  }

export function newVideo(): VideoConfigType {
  return {
    ...DefaultBlockConfigValues(),
    id: uuidv4(),
    type: VideoTypeId,
    url: '',
    controls: false,
    width: 640,
    height: 480,
    mobile: {
      ...DefaultBlockConfigValues().mobile,
      width: 640,
      height: 480,
    },
  }
}

const StyledVideo = styled.div<{block: VideoConfigType}>`
  border-radius: ${(props) => props.block.border.radius}px;
  border-width: ${(props) => props.block.border.width}px;
  border-color: ${(props) => props.block.border.color};
  border-style: solid;
  width: ${(props) => props.block.width}px;
  height: ${(props) => props.block.height}px;

  & > div {
    width: 100% !important;
    height: 100% !important;
  }

  @container (max-width: 991px) {
    width: ${(props) => props.block.mobile.width}px;
    height: ${(props) => props.block.mobile.height}px;
  }
`

const NoVideoHolder = styled(PreviewHolderDiv)<{block: VideoConfigType}>`
  display: flex;
  align-items: center;
  justify-content: center;

  border: 1px solid #000000;

  width: ${(props) => props.block.width}px;
  height: ${(props) => props.block.height}px;

  width: ${(props) => props.block.width}px;
  height: ${(props) => props.block.height}px;

  @container (max-width: 991px) {
    width: ${(props) => props.block.mobile.width}px;
    height: ${(props) => props.block.mobile.height}px;
  }
`
