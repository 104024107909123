import usePayment from 'attendee/Billing/Payments/usePayment'
import {EventModel} from 'event/models/Event'
import {useNavigate} from 'react-router-dom'
import {routes} from 'routes/routes'

export default function useTicketPayment(event: EventModel | null) {
  const {newPayment} = usePayment()
  const navigate = useNavigate()

  const onSuccess = () => {
    navigate(routes.tickets)
  }

  const payment = () => {
    return newPayment(
      `api/payment/event/${event?.id}/buy-ticket`,
      `api/payment/event/${event?.id}/ticket-confirmation`,
      {},
      onSuccess,
    )
  }

  return {
    payment,
  }
}
