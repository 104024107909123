import {FreeEventAddons} from 'organization/Event/Addons/FreeEventAddons'
import {PaidEventAddons} from 'organization/Event/Addons/PaidEventAddons'
import EventPage from 'organization/Event/EventPage'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'

export const Addons = () => {
  const {event} = useOrganizationEvent()
  console.log(event?.is_free)
  return (
    <EventPage>
      <>
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Добавки" />

          <div className="event__venue-area">
            <div className="row">
              {Boolean(event?.is_free) && <FreeEventAddons />}
              {!Boolean(event?.is_free) && <PaidEventAddons />}
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}
