import React from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {BorderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/BorderInput'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {PaddingInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/PaddingInput'
import {SwitchInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SwitchInput'
import {
  Border,
  Padding,
  StyledTitle,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {removeByNestedKeys} from 'utils/lib/json'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'
import {confirmAlert} from 'react-confirm-alert'
import {ImageInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ImageInput'
import {VisibilityInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/VisibilityInput'

export type SectionConfigType = {
  padding: Padding
  background: string | null
  backgroundImage: string | null
  border: Border
  revertMobile: boolean
  visibility: 'all' | 'desktop' | 'mobile'
  visible: boolean
}

export function SectionConfig() {
  const {setEditElement, editElement, mobileMode, setMobileMode} = useEditor()
  const {template, updateTemplate} = useTemplate()

  const handleToggleMobileMode = () => setMobileMode(!mobileMode)

  const {handleSubmit, values, setFieldValue} =
    useFormicForm<SectionConfigType>()

  const removeSection = () => {
    confirmAlert({
      message: 'Сигурни ли сте?',
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            setEditElement(null)
            updateTemplate(removeByNestedKeys(template, editElement))
          },
        },
        {
          label: 'Не',
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <>
      <div className={'offcanvas__editor info-open'}>
        <div className="offcanvas__wrapper">
          <div
            className="offcanvas__content"
            style={{
              overflow: 'scroll',
              bottom: '0',
              top: '100px',
              width: '290px',
            }}
          >
            <div className={`bd-theme-toggle mb-20 d-block`}>
              <label
                className="bd-theme-toggle-main"
                style={{width: '100%'}}
                htmlFor="bd-theme-toggler2"
              >
                <span
                  className={`bd-theme-toggle-dark ${!mobileMode ? 'active' : ''}`}
                >
                  <LaptopMacIcon /> Стандартна
                </span>
                <input
                  type="checkbox"
                  id="bd-theme-toggler2"
                  checked={!mobileMode}
                  onChange={handleToggleMobileMode}
                />
                <i className="bd-theme-toggle-slide"></i>
                <span
                  className={`bd-theme-toggle-light ${mobileMode ? 'active' : ''}`}
                >
                  <SmartphoneIcon /> Мобилна
                </span>
              </label>
            </div>

            <div style={{display: 'flex', gap: 10}}>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{
                  background: 'none',
                  color: '#f87a58',
                  border: '1px solid #f87a58',
                  height: '35px',
                }}
                onClick={() => {
                  setEditElement(null)
                }}
              >
                {`Затвори`}
              </button>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{height: '35px'}}
                onClick={removeSection}
              >
                Премахване
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <button className={'input__btn w-100 mb-20'} type="submit">
                Запазване
              </button>

              <SwitchInput
                value={values.visible ?? false}
                name={'visible'}
                label="Видим за потребителите"
                setFieldValue={setFieldValue}
              />

              <VisibilityInput
                value={values.visibility}
                name={'visibility'}
                label="Видим на устройства"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Основни</StyledTitle>
              <ColorInput
                value={values.background ?? '#ffffff'}
                name={'background'}
                label="Цвят на фона"
                setFieldValue={setFieldValue}
              />

              <ImageInput
                value={values.backgroundImage}
                name={'backgroundImage'}
                label="Изображение на фона"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Съдържание</StyledTitle>

              <SwitchInput
                value={values.revertMobile ?? false}
                name={'revertMobile'}
                label="Размени при мобилна"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Граници</StyledTitle>

              <BorderInput
                value={values.border}
                name={'border'}
                label="Граници"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Отстояния</StyledTitle>

              <PaddingInput
                value={values.padding}
                name={'padding'}
                label="Отстояния"
                setFieldValue={setFieldValue}
                max={500}
                min={0}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
