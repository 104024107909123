import {FAQ} from 'attendee/Help/FAQ'
import Page from 'attendee/theme/Page'
import React from 'react'
import {Link} from 'react-router-dom'
import contactsImage from 'images/contacts.png'

export function Help() {
  return (
    <Page>
      <section className="contact_section dark_light_class pt-55 pb-150">
        <div className="container">
          <h2 className="section__title mb-35">
            Често <span className="text__highlight"> задавани въпроси</span>
          </h2>

          <FAQ />

          <h2 className="section__title mb-35 mt-80">
            Връзка с <span className="text__highlight"> нас</span>
          </h2>

          <div className="row g-4">
            <div className=" col-12 col-lg-6">
              <img src={contactsImage} width="100%" alt="" />
            </div>

            <div className="col-12 col-lg-6">
              <ul className="contact_info_list style_2 ps-lg-4 unordered_list_block">
                <li>
                  <strong>Телефон:</strong>
                  Скрит
                </li>
                <li>
                  <strong>Имейл:</strong>
                  <Link to="mailto:support@eventa.tv" target="_blank">
                    support@eventa.tv
                  </Link>
                </li>
                <li>
                  <strong>Работно време:</strong>
                  <span>09:00 - 15:00, от Понеделник до Петък</span>
                </li>
                <li>
                  <strong>Последвай ни:</strong>
                  <div className="social_icon unordered_list">
                    <div className={'footer__social'}>
                      <a
                        href="https://www.facebook.com/eventatv"
                        target="_blank"
                        rel="noreferrer"
                        aria-label="facebook"
                      >
                        <i className="fa-brands fa-facebook"></i>
                      </a>
                    </div>
                  </div>
                </li>
              </ul>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}
