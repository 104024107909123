'use client'
import React, {useState} from 'react'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {ProfileLinks} from 'attendee/theme/Header/ProfileLinks'
import HeaderNotification from 'attendee/theme/Header/HeaderNotification'
import {useTheme} from 'attendee/theme/ThemeProvider'
import {Show} from 'utils/ui/Show'
import {Link, useNavigate} from 'react-router-dom'
import {routes} from 'routes/routes'
import FullscreenIcon from '@mui/icons-material/Fullscreen'
import {useQueryParams} from 'utils/lib/url'

export const Header = () => {
  const {user} = useAuth()
  const navigate = useNavigate()
  const {term} = useQueryParams()
  const {SidebarHandle} = useTheme()
  const [isFullScreen, setIsFullScreen] = useState<boolean>(false)
  const [isOpenNotification, setIsOpenNotification] = useState<boolean>(false)

  const handleShowNotification = () => {
    setIsOpenNotification(!isOpenNotification)
  }

  const handleToggleFullScreen = () => {
    if (!isFullScreen) {
      enterFullScreen()
    } else {
      exitFullScreen()
    }
  }

  const enterFullScreen = () => {
    const docElement = document.documentElement

    if (docElement.requestFullscreen) {
      docElement.requestFullscreen()
    } else if ((docElement as any).mozRequestFullScreen) {
      ;(docElement as any).mozRequestFullScreen()
    } else if ((docElement as any).webkitRequestFullscreen) {
      ;(docElement as any).webkitRequestFullscreen()
    }
    setIsFullScreen(true)
  }

  const exitFullScreen = () => {
    if (document.exitFullscreen) {
      document.exitFullscreen()
    } else if ((document as any).mozCancelFullScreen) {
      ;(document as any).mozCancelFullScreen()
    } else if ((document as any).webkitExitFullscreen) {
      ;(document as any).webkitExitFullscreen()
    }
    setIsFullScreen(false)
  }

  const [searchTerm, setSearchTerm] = useState<string>(term ?? '')
  const handleSearch = () => {
    navigate(`${routes.search}?term=${searchTerm}`)
  }

  const handleSearchKeyDown = (e: any) => {
    if (e.key === 'Enter') {
      handleSearch()
    }
  }

  return (
    <div className="app__header__area">
      <div className="app__header-inner">
        <div className="app__header-left">
          <button
            onClick={SidebarHandle}
            id="sidebar__active"
            aria-label="sidebar toggle"
          >
            <div className="bar-icon-2">
              <span></span>
              <span></span>
              <span></span>
            </div>
          </button>
          <div className="app__herader-input p-relative">
            <input
              type="search"
              value={searchTerm}
              onChange={(e) => setSearchTerm(e.target.value)}
              onKeyDown={handleSearchKeyDown}
              placeholder="Намери събитие . . ."
            />
            <button
              onClick={handleSearch}
              aria-label="search button"
              style={{width: 50, height: 50, insetInlineEnd: 0}}
            >
              <i className="flaticon-loupe"></i>
            </button>
          </div>
        </div>
        <div className="app__header-right">
          <div className="app__header-action">
            <ul>
              <li className="full-screen-button">
                <button
                  onClick={handleToggleFullScreen}
                  aria-label="full screen button"
                >
                  <div className="nav-item">
                    <div className="notification__icon">
                      <FullscreenIcon />
                    </div>
                  </div>
                </button>
              </li>
              {/* <Show if={user}>
                <li>
                  <HeaderNotification
                    handleShowNotification={handleShowNotification}
                    isOpenNotification={isOpenNotification}
                  />
                </li>
              </Show> */}
            </ul>
          </div>

          <Show if={user}>
            <ProfileLinks />
          </Show>
          <Show if={!user}>
            <div>
              <Link to={routes.login} className="fill__btn">
                Вход <i className="fa-regular fa-angle-right"></i>
              </Link>
            </div>
          </Show>
        </div>
      </div>
    </div>
  )
}
