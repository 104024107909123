'use client'
import React, {useEffect, useState} from 'react'
import {useTheme} from 'attendee/theme/ThemeProvider'
import {Menu} from 'attendee/theme/Sidebar/Menu'

const DashbordSidebar = () => {
  const {isCollapse, SidebarHandle} = useTheme()

  const [deviceWidth, setDeviceWidth] = useState<number | null>(null)
  useEffect(() => {
    const handleResize = () => {
      setDeviceWidth(window.innerWidth)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => {
      window.removeEventListener('resize', handleResize)
    }
  }, [])

  return (
    <>
      {deviceWidth && deviceWidth <= 991 ? (
        <div
          onClick={SidebarHandle}
          className={
            isCollapse ? 'expovent__sidebar' : 'expovent__sidebar open'
          }
        >
          <Menu />
        </div>
      ) : (
        <div className={`expovent__sidebar ${isCollapse ? 'collapsed' : ' '}`}>
          <Menu />
        </div>
      )}
      <div
        onClick={SidebarHandle}
        className={`app__offcanvas-overlay ${isCollapse ? '' : 'overlay-open'}`}
      ></div>
    </>
  )
}

export default DashbordSidebar
