import React from 'react'
import Form from 'react-bootstrap/Form'
import {Image, ImageSelector} from 'organization/ImageSelector'

export function ImageInput(props: {
  value: string | null
  setFieldValue: (field: string, value: string | null) => void
  label: string
  name: string
}) {
  const {setFieldValue, name, label} = props

  const handleOnSelect = (image: Image | null) => {
    setFieldValue(name, image?.url ?? null)
  }

  return (
    <>
      <Form.Group className="mb-3">
        <Form.Label>{label}</Form.Label>
        <ImageSelector onSelect={handleOnSelect} />
      </Form.Group>
    </>
  )
}
