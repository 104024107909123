import {useEditor} from 'TemplateEditor/EditorProvider'
import {ListBlocks} from 'TemplateEditor/Layout/Blocks'
import {Config} from 'TemplateEditor/Layout/Blocks/Config'
import {Content} from 'TemplateEditor/Layout/Content'
import {ListSections} from 'TemplateEditor/Layout/Sections/Section'
import {SectionConfig} from 'TemplateEditor/Layout/Sections/SectionConfig'
import {SubSectionConfig} from 'TemplateEditor/Layout/Sections/SubSectionConfig'
import {Sidebar} from 'TemplateEditor/Layout/Sidebar'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {Section, Template} from 'TemplateEditor/template'
import React from 'react'
import {DragDropContext, DropResult} from 'react-beautiful-dnd'
import styled from 'styled-components'

export function Layout() {
  const {template, updateTemplate} = useTemplate()
  const {editMode, mobileMode} = useEditor()

  return (
    <Container>
      <DragDropContext
        onDragEnd={(result: DropResult) => {
          handleDragEnd(result, template, updateTemplate)
        }}
      >
        <Editor>
          <InnerContainer
            className="container"
            editMode={editMode}
            mobileMode={mobileMode}
          >
            <Content />
          </InnerContainer>

          <SideBarRender />
        </Editor>
      </DragDropContext>
    </Container>
  )
}

function SideBarRender() {
  const {editMode, keepSidebar, editElement} = useEditor()

  const isSectionEdit = isSectionElement(editElement)

  if (isSectionEdit) return <SectionConfig />

  const isSubSectionEdit = isSubSectionElement(editElement)

  if (isSubSectionEdit) return <SubSectionConfig />

  if (editElement) return <Config />

  if (editMode || keepSidebar) return <Sidebar />

  return null
}

function isSectionElement(editElement: string | null) {
  // sections.${sectionIndex}
  if (!editElement) return false

  const keys = editElement.split('.')

  if (keys.length === 2 && keys[0] === 'sections') return true

  return false
}

function isSubSectionElement(editElement: string | null) {
  // sections.${sectionIndex}.sub.${index}
  if (!editElement) return false

  const keys = editElement.split('.')

  if (keys.length === 4 && keys[0] === 'sections' && keys[2] === 'innerStyles')
    return true

  return false
}

const handleDragEnd = (
  result: DropResult,
  template: Template,
  updateTemplate: (template: Template) => void,
) => {
  const {source, destination, draggableId} = result

  const sections = !template.sections
    ? (template.sections = [])
    : template.sections

  // new section init
  if (
    destination?.droppableId === 'editor-sections' &&
    source?.droppableId === 'default-sections'
  ) {
    for (const ListSection of ListSections) {
      if (draggableId === ListSection.typeId) {
        sections.splice(destination.index, 0, ListSection.new())
      }
    }
  } else if (destination && source?.droppableId === 'default-blocks') {
    for (const ListBlock of ListBlocks) {
      if (draggableId === ListBlock.typeId) {
        const dest = destination.droppableId.split('.')

        const sectionKey = findSection(dest[0], sections)

        if (!sections[Number(sectionKey)].hasOwnProperty('blocks')) {
          sections[Number(sectionKey)].blocks = {}
        }

        if (
          !sections[Number(sectionKey)].blocks.hasOwnProperty(Number(dest[1]))
        ) {
          sections[Number(sectionKey)].blocks[Number(dest[1])] = []
        }

        sections[Number(sectionKey)].blocks[Number(dest[1])].splice(
          destination.index,
          0,
          ListBlock.new(),
        )
      }
    }
  } else if (
    destination?.droppableId === 'editor-sections' &&
    source?.droppableId === 'editor-sections'
  ) {
    const [removed] = sections.splice(source.index, 1)
    sections.splice(destination.index, 0, removed)
  } else if (destination && source) {
    const dest = destination.droppableId.split('.')
    const sour = source.droppableId.split('.')

    const sectionKey = findSection(dest[0], sections)

    if (!sections[Number(sectionKey)].hasOwnProperty('blocks')) {
      sections[Number(sectionKey)].blocks = {}
    }

    if (!sections[Number(sectionKey)].blocks.hasOwnProperty(Number(dest[1]))) {
      sections[Number(sectionKey)].blocks[Number(dest[1])] = []
    }

    const sectionSourKey = findSection(sour[0], sections)

    if (!sections[Number(sectionSourKey)].hasOwnProperty('blocks')) {
      sections[Number(sectionSourKey)].blocks = {}
    }

    if (
      !sections[Number(sectionSourKey)].blocks.hasOwnProperty(Number(dest[1]))
    ) {
      sections[Number(sectionSourKey)].blocks[Number(dest[1])] = []
    }

    const [removed] = sections[Number(sectionSourKey)].blocks[
      Number(sour[1])
    ].splice(source.index, 1)
    sections[Number(sectionKey)].blocks[Number(dest[1])].splice(
      destination.index,
      0,
      removed,
    )
  }

  updateTemplate({
    ...template,
    sections: sections,
  })
}

function findSection(sectionId: string, sections: Section[]) {
  return Object.keys(sections).find(
    (key) => sections[Number(key)].id === sectionId,
  )
}

const Container = styled.div`
  height: 100%;
  margin: 0;
`

const InnerContainer = styled.div<{editMode: boolean; mobileMode: boolean}>`
  height: ${(props) => (props.editMode ? 'calc(100vh - 76px)' : '100%')};
  overflow: auto;
  container-type: inline-size;
  width: 100%;
  max-width: ${(props) => (props.mobileMode ? '390px' : 'inherit')};
  padding: 0;
`

const Editor = styled.div`
  display: flex;
  height: 100%;
  transition: all 0.3s ease;
`
