import React from 'react'
import {SwitchInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SwitchInput'
import {VisibilityInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/VisibilityInput'
import {StyledTitle} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function DefaultBlockTopConfig(props: {
  values: any
  setFieldValue: any
}) {
  const {values, setFieldValue} = props

  return (
    <>
      <SwitchInput
        value={values.visible ?? false}
        name={'visible'}
        label="Видим за потребителите"
        setFieldValue={setFieldValue}
      />

      <VisibilityInput
        value={values.visibility}
        name={'visibility'}
        label="Видим на устройства"
        setFieldValue={setFieldValue}
      />
      <StyledTitle>Основни</StyledTitle>
    </>
  )
}
