import {PaymentIntent, PaymentMethod} from '@stripe/stripe-js'
import {attendeeApiClient} from 'attendee/apiClient'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {confirmAlert} from 'react-confirm-alert'

export interface AttendeeBillingContextProps {
  loading: boolean
  setLoading: (val: boolean) => void
  paymentMethods: PaymentMethod[]
  getPaymentMethods: () => Promise<void>
  removePaymentMethod: (paymentMethod: string) => Promise<void>
  payments: PaymentIntent[]
  getPayments: () => Promise<void>
  createConfirmationIntent: (confirmationTokenId: string) => Promise<void>
  payModalOpen: boolean
  setPayModalOpen: (set: boolean) => void
  payPayment: (
    payment: (paymentMethodId: string) => Promise<void>,
    freePayment?: boolean,
  ) => void
  selectPaymentMethod: (paymentMethodId: string) => void
  isFreePayment: boolean
}

export const AttendeeBillingContext = React.createContext<
  AttendeeBillingContextProps | undefined
>(undefined)

export default function AttendeeBillingProvider(props: {
  children: React.ReactElement
}) {
  const [payModalOpen, setPayModalOpen] = useState(false)
  const apiClient = attendeeApiClient()
  const [paymentMethods, setPaymentMethods] = useState<PaymentMethod[]>([])
  const [payments, setPayments] = useState<PaymentIntent[]>([])
  const [loading, setLoading] = useState<boolean>(true)
  const [paying, setPaying] = useState<boolean>(false)
  const [isFreePayment, setIsFreePayment] = useState<boolean>(false)

  const getPaymentMethods = async () => {
    setLoading(true)
    apiClient
      .get('api/payments/payment-methods')
      .then((response) => setPaymentMethods(response))
      .catch((error) => {})
      .finally(() => {
        setLoading(false)
      })
  }

  const removePaymentMethod = async (paymentMethod: string) => {
    confirmAlert({
      message: 'Сигурни ли сте, че искате да премахнете начина на плащане?',
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            setLoading(true)

            apiClient
              .post(`api/payments/remove-payment-method`, {
                paymentMethod,
              })
              .then(() => {
                setPaymentMethods((current) =>
                  current.filter((pMethod) => pMethod.id !== paymentMethod),
                )
                toast.success('Начина на плащане е премахнат.')
              })
              .catch(() => {
                toast.warning('Начина на плащане не може да се премахне.')
              })
              .finally(() => {
                setLoading(false)
              })
          },
        },
        {
          label: 'Не',
          onClick: () => {
            // nothing
          },
        },
      ],
    })
  }

  const getPayments = async () => {
    setLoading(true)
    apiClient
      .get('api/payments/payments')
      .then((response) => setPayments(response))
      .finally(() => {
        setLoading(false)
      })
  }

  const createConfirmationIntent = async (confirmationTokenId: string) => {
    setLoading(true)
    return apiClient
      .post('api/payments/create-confirmation-intent', {
        confirmationTokenId: confirmationTokenId,
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const [currentPayment, setCurrentPayment] =
    useState<() => (paymentMethodId: string) => Promise<void>>()
  const payPayment = (
    payment: (paymentMethodId: string) => Promise<void>,
    freePayment?: boolean,
  ) => {
    if (paying) return

    setIsFreePayment(freePayment ?? false)

    setPayModalOpen(true)
    setCurrentPayment(() => () => payment)
  }

  const selectPaymentMethod = (paymentMethodId: string) => {
    setLoading(true)
    setPaying(true)

    if (!currentPayment) {
      setPayModalOpen(false)
      setLoading(false)
      setPaying(false)
      return
    }

    const payment = currentPayment()

    payment(paymentMethodId).then(() => {
      setPayModalOpen(false)
      setLoading(false)
      setPaying(false)
    })
  }

  return (
    <AttendeeBillingContext.Provider
      value={{
        loading,
        setLoading,
        paymentMethods,
        getPaymentMethods,
        removePaymentMethod,
        payments,
        getPayments,
        createConfirmationIntent,
        payModalOpen,
        setPayModalOpen,
        payPayment,
        selectPaymentMethod,
        isFreePayment,
      }}
    >
      {props.children}
    </AttendeeBillingContext.Provider>
  )
}

export function useAttendeeBilling() {
  const context = React.useContext(AttendeeBillingContext)
  if (context === undefined) {
    throw new Error(
      'useAttendeeBilling must be used within AttendeeBillingProvider',
    )
  }
  return context
}
