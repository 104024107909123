import React from 'react'
import {ApexOptions} from 'apexcharts'
import Chart from 'react-apexcharts'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'

const TotalSeats = () => {
  const {event} = useOrganizationEvent()

  const occupiedPercents =
    ((event?.available_seats ?? 0) / (event?.total_seats ?? 0)) * 100

  const chartOptions: ApexOptions = {
    series: [occupiedPercents],
    chart: {
      height: 200,
      type: 'radialBar',
      toolbar: {
        show: true,
      },
    },
    plotOptions: {
      radialBar: {
        hollow: {
          size: '75%',
          background: '#2C2C2C',
          imageOffsetX: 0,
          imageOffsetY: 0,
          margin: 10,
        },
        track: {
          background: 'rgba(255, 163, 0, 1)',
          strokeWidth: '10%',
        },
        dataLabels: {
          show: true,
          name: {
            offsetY: -7,
            show: true,
            color: '#7C7C7C',
            fontSize: '14px',
            fontFamily: 'Inter, sans-serif',
            fontWeight: 'regular',
          },
          value: {
            offsetY: 7,
            color: '#fff',
            fontSize: '18px',
            show: true,
            fontFamily: 'Inter, sans-serif',
            fontWeight: 'bold',
          },
        },
      },
    },
    labels: ['Свободни места'],
    fill: {
      type: 'gradient',
      gradient: {
        shadeIntensity: 1,
        opacityFrom: 0.7,
        opacityTo: 0.9,
        colorStops: [
          {
            offset: 20,
            color: '#FA631E',
            opacity: 1,
          },
          {
            offset: 60,
            color: '#FFAD01',
            opacity: 1,
          },
        ],
      },
    },
  }

  return (
    <div className="col-xxl-4 col-xl-6 col-lg-6 ">
      <div className="card__wrapper total__seat-wapper">
        <div className="card__header mb-35">
          <div className="card__header-top">
            <div className="card__title-inner">
              <div className="card__header-icon">
                <i className="flaticon-chair-2"></i>
              </div>
              <div className="card__header-title">
                <h4>Продадени места</h4>
              </div>
            </div>
          </div>
        </div>
        <div className="total__seat-progress">
          <div id="radialChart">
            <Chart
              options={chartOptions}
              series={chartOptions.series}
              type="radialBar"
              height={200}
            />
          </div>
          <div className="redialchart__content">
            <div className="redialchart__info">
              <span>Общо места</span>
              <h4>{event?.total_seats}</h4>
            </div>
            <div className="redialchart__info">
              <span>Свободни места</span>
              <h4>{event?.available_seats}</h4>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default TotalSeats
