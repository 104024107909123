import Axios from 'axios'

export const organizationAccessTokenkey = 'organization_access_token'

export function organizationApiClient() {
  const get = async (url: string) => {
    const res = await axios().get(url)
    return res.data
  }

  const post = async (url: string, data?: any, options?: any) => {
    const res = await axios().post(url, data, options)
    return res.data
  }

  const remove = async (url: string) => {
    const res = await axios().delete(url)
    return res.data
  }
  return {
    get,
    post,
    remove,
  }
}

function getAccessToken() {
  return localStorage.getItem(organizationAccessTokenkey)
}

function axios() {
  const instance = Axios.create({
    baseURL: process.env.REACT_APP_BACKEND_URL,
    headers: {
      Accept: 'application/json',
      'Content-Type': 'multipart/form-data;',
      Authorization: 'Bearer ' + getAccessToken(),
    },
  })

  return instance
}
