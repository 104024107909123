import React from 'react'
import {confirmAlert} from 'react-confirm-alert'
import styled from 'styled-components'
import {ExampleTemplate} from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplatesProvider'
import {Template} from 'TemplateEditor/template'
import {useTemplate} from 'TemplateEditor/TemplateProvider'

export default function ExampleTemplateItem(props: {
  exampleTemplate: ExampleTemplate
  toggleOpen: () => void
}) {
  const {exampleTemplate, toggleOpen} = props
  const {updateTemplate} = useTemplate()

  const selectTemplate = (template: Template) => {
    confirmAlert({
      message:
        'Всички досегашни промени ще бъдат заменени с примерния дизайн. Сигурни ли сте?',
      closeOnClickOutside: false,
      buttons: [
        {
          label: 'Да',
          onClick: () => {
            updateTemplate(exampleTemplate.template)
            toggleOpen()
          },
        },
        {
          label: 'Не',
          onClick: () => {},
        },
      ],
    })
  }

  return (
    <ItemHolder>
      <ItemImageHolder>
        <img
          src={
            process.env.PUBLIC_URL +
            `/example-templates/${exampleTemplate.image}`
          }
          alt="example template 1"
        />
      </ItemImageHolder>
      <ItemNameHolder>{exampleTemplate.name}</ItemNameHolder>
      <ItemActionsHolder>
        <button
          type="button"
          className={'input__btn w-100 '}
          onClick={() => selectTemplate(exampleTemplate.template)}
        >
          Приложи
        </button>
      </ItemActionsHolder>
    </ItemHolder>
  )
}

const ItemHolder = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
`

const ItemNameHolder = styled.div`
  color: #333;
`

const ItemImageHolder = styled.div`
  & > img {
    width: 500px;
  }
`

const ItemActionsHolder = styled.div``
