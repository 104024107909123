import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import React, {useEffect, useState} from 'react'
import {GoogleLogin, GoogleOAuthProvider} from '@react-oauth/google'
import {toast} from 'react-toastify'

export function GoogleOneTab() {
  const {oneTabGoogleLogin, user} = useAuth()
  const [showLogin, setShowLogin] = useState(false)

  useEffect(() => {
    const timer = setTimeout(() => {
      setShowLogin(true)
    }, 1000) // 1 second delay

    return () => clearTimeout(timer) // Clean up the timer
  }, [])

  if (user || !showLogin) return null

  return (
    <GoogleOAuthProvider
      clientId={process.env.REACT_APP_GOOGLE_CLIENT_ID ?? ''}
    >
      <GoogleLogin
        onSuccess={oneTabGoogleLogin}
        auto_select={true}
        containerProps={{style: {display: 'none'}}}
        onError={() => {
          toast.warning('Login Failed')
        }}
        useOneTap
      />
    </GoogleOAuthProvider>
  )
}
