import React from 'react'
import styled from 'styled-components'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {BlockElements} from 'TemplateEditor/Layout/Blocks'

export function DefaultBlockHolder(props: {
  children: JSX.Element
  key: number | string
  block: BlockElements
  onClick: () => void
}) {
  const {editMode, mobileMode} = useEditor()

  if (!editMode && !props.block.visible) {
    return null
  }

  return (
    <Holder
      $block={props.block}
      onClick={props.onClick}
      $mobileMode={mobileMode}
      $editMode={editMode}
    >
      {props.children}
    </Holder>
  )
}

const Holder = styled.div<{
  $block: BlockElements
  $mobileMode: boolean
  $editMode: boolean
}>`
  display: flex;
  width: 100%;
  justify-content: center;
  align-items: center;
  ${(props) => (!props.$block.visible ? 'border: 2px #f7496d solid' : '')};
  padding: ${(props) =>
      props.$mobileMode
        ? props.$block.mobile.padding.top
        : props.$block.padding.top}px
    ${(props) =>
      props.$mobileMode
        ? props.$block.mobile.padding.right
        : props.$block.padding.right}px
    ${(props) =>
      props.$mobileMode
        ? props.$block.mobile.padding.bottom
        : props.$block.padding.bottom}px
    ${(props) =>
      props.$mobileMode
        ? props.$block.mobile.padding.left
        : props.$block.padding.left}px;
  background: ${(props) => props.$block.background};

  ${(props) =>
    props.$block.visibility === 'desktop'
      ? '@container (max-width: 991px) { display: none;}'
      : ''};
  ${(props) =>
    props.$block.visibility === 'mobile'
      ? '@container (min-width: 991px) { display: none;}'
      : ''};
`
