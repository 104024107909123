import {publicImageUrl} from 'organization/ImageSelector'
import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import AddPhotoAlternateIcon from '@mui/icons-material/AddPhotoAlternate'
import styled from 'styled-components'

export const ImageBlockTypeId = 'block-image'

export function ImageBlock(props: {
  block: ImageBlockConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <>
        {block.image && (
          <ResponsiveImage
            block={block}
            src={publicImageUrl(block.image)}
            alt="event img"
          />
        )}
        {!block.image && <PreviewImageBlock />}
      </>
    </DefaultBlockHolder>
  )
}

export function PreviewImageBlock() {
  return (
    <PreviewHolderDiv>
      <AddPhotoAlternateIcon /> <span>Изображение</span>
    </PreviewHolderDiv>
  )
}

export type ImageBlockConfigType = Block &
  DefaultBlockConfigType & {
    image: string | null
    width: number
    mobile: {
      width: number
    }
  }

export function newImageBlock(): ImageBlockConfigType {
  return {
    ...DefaultBlockConfigValues(),
    id: uuidv4(),
    type: ImageBlockTypeId,
    image: null,
    width: 50,
    mobile: {
      ...DefaultBlockConfigValues().mobile,
      width: 100,
    },
  }
}

const ResponsiveImage = styled.img<{block: ImageBlockConfigType}>`
  width: ${(props) => props.block.width}%;
  border-radius: ${(props) => props.block.border.radius}px;
  border-width: ${(props) => props.block.border.width}px;
  border-color: ${(props) => props.block.border.color};
  border-style: solid;

  @container (max-width: 991px) {
    width: ${(props) => props.block.mobile.width}%;
  }
`
