import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {StripeConnection} from 'stripe/stripeConnection'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export interface StripeConnectionContextProps {
  addConnection: (stripeEmail: string) => void
  removeConnection: () => void
  getDashboard: () => void
  stripeConnection: StripeConnection | null
  loading: boolean
}

export const StripeConnectionContext = React.createContext<
  StripeConnectionContextProps | undefined
>(undefined)

export default function StripeConnectionProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [loading, setLoading] = useState<boolean>(true)
  const eventId = useRouteOrganizationEventId()
  const [stripeConnection, setStripeConnection] =
    useState<StripeConnection | null>(null)
  const [initLoading, setInitLoading] = useState<boolean>(true)

  useEffect(() => {
    setInitLoading(true)
    get()
  }, [])

  const get = async () => {
    await apiClient
      .get(`api/event/${eventId}/stripe/connection`)
      .then((response) => {
        setStripeConnection(response)
      })
      .finally(() => {
        setLoading(false)
        setInitLoading(false)
      })
  }

  const addConnection = async (stripeEmail: string) => {
    setLoading(true)

    await apiClient
      .post(`api/event/${eventId}/stripe/addConnection`, {
        stripeEmail,
      })
      .then((response) => {
        window.open(response.url)?.focus()
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const removeConnection = async () => {
    setLoading(true)

    await apiClient
      .remove(`api/event/${eventId}/stripe/disconnectConnection`)
      .then(() => {
        setStripeConnection(null)
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  const getDashboard = async () => {
    setLoading(true)

    return await apiClient
      .get(`api/event/${eventId}/stripe/dashboard`)
      .then((response) => {
        window.open(response.url, '_blank')?.focus()
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
      })
      .finally(() => {
        setLoading(false)
      })
  }

  return (
    <StripeConnectionContext.Provider
      value={{
        addConnection,
        removeConnection,
        getDashboard,
        stripeConnection,
        loading,
      }}
    >
      {initLoading && <FullPageLoader />}
      {!initLoading && props.children}
    </StripeConnectionContext.Provider>
  )
}

export function useStripeConnection() {
  const context = React.useContext(StripeConnectionContext)
  if (context === undefined) {
    throw new Error(
      'useStripeConnection must be used within StripeConnectionProvider',
    )
  }
  return context
}
