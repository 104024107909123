import React from 'react'
import {Navigate, Route, Routes, useParams} from 'react-router-dom'
import {routes, routesWithValue} from 'routes/routes'
import List from 'organization/List'
import OrganizationsProvider from 'organization/providers/OrganizationsProvider'
import {Organization} from 'organization/models/Organization'
import OrganizationProvider from 'organization/providers/OrganizationProvider'
import {useURLHasOrganizationID} from 'utils/lib/url'
import {EventModel} from 'event/models/Event'
import {EventIndex} from 'organization/Event'
import {EventDesign} from 'organization/Event/Design'
import OrganizationEventProvider from 'organization/Event/OrganizationEventProvider'
import {Billing} from 'organization/Billing'
import OrganizationEventsListProvider from 'organization/ListEvents/providers/OrganizationEventsListProvider'
import {EventsList} from 'organization/ListEvents'
import {CreateEvent} from 'organization/CreateEvent'
import {Settings} from 'organization/Event/Settings'
import {Addons} from 'organization/Event/Addons'
import {Tickets} from 'organization/Event/Tickets'
import {Vision} from 'organization/Event/Vision'
import {Earning} from 'organization/Event/Earning'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {Login} from 'organization/Auth/Login'
import {Register} from 'organization/Auth/Register'
import EventAttendantsProvider from 'organization/Event/Attendants/EventAttendantsProvider'
import {Attendants} from 'organization/Event/Attendants'
import {ViewAttendant} from 'organization/Event/Attendants/ViewAttendant'
import EventEarningProvider from 'organization/Event/Earning/EventEarningProvider'
import {GoogleCallback} from 'organization/Auth/GoogleCallback'
import {PasswordRecover} from 'organization/Auth/PasswordRecover'
import {Profile} from 'organization/Auth/Profile'
import {Management} from 'organization/Event/Management'
import MeetingProvider from 'organization/Event/MeetingProvider'
import AttendantProvider, {
  Attendant,
} from 'organization/Event/Attendants/ViewAttendant/AttendantProvider'
import {Help} from 'organization/Event/Help'
import StripeConnectedCallbackHandler from 'organization/Event/Earning/StripeConnectedCallbackHandler'
import StripeConnectionProvider from 'organization/Event/Earning/StripeConnectionProvider'
import {OrganizationAutologin} from 'organization/Auth/OrganizationAutologin'

export function OrganizationRoutes() {
  const {user} = useOrganizationAuth()
  const urlHasOrganizationID = useURLHasOrganizationID()

  if (!user) {
    return (
      <Routes>
        <Route
          path={routes.organization.autologin}
          element={<OrganizationAutologin />}
        />
        <Route path={routes.organization.login} element={<Login />} />

        <Route path={routes.organization.register} element={<Register />} />

        <Route
          path={routes.organization.auth.google}
          element={<GoogleCallback />}
        />

        <Route
          path={routes.organization.passwordRecover}
          element={<PasswordRecover />}
        />

        <Route
          path="*"
          element={<Navigate to={routes.organization.login} replace />}
        />
      </Routes>
    )
  }

  if (!urlHasOrganizationID) {
    return (
      <Routes>
        <Route path={routes.organization.profile} element={<Profile />} />

        <Route path={routes.organization.billing} element={<Billing />} />

        <Route
          path={routes.organization.list}
          element={
            <OrganizationsProvider>
              <List />
            </OrganizationsProvider>
          }
        />

        <Route
          path="*"
          element={<Navigate to={routes.organization.list} replace />}
        />
      </Routes>
    )
  }

  return (
    <OrganizationProvider>
      <Routes>
        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].attendant[
              ':attendantId'
            ].view
          }
          element={
            <OrganizationEventProvider>
              <AttendantProvider>
                <ViewAttendant />
              </AttendantProvider>
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].events}
          element={
            <OrganizationEventsListProvider>
              <EventsList />
            </OrganizationEventsListProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event.create.root}
          element={<CreateEvent />}
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].attendants
          }
          element={
            <OrganizationEventProvider>
              <EventAttendantsProvider>
                <Attendants />
              </EventAttendantsProvider>
            </OrganizationEventProvider>
          }
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].management
          }
          element={
            <OrganizationEventProvider>
              <MeetingProvider>
                <Management />
              </MeetingProvider>
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].page}
          element={
            <OrganizationEventProvider>
              <EventDesign />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].vision}
          element={
            <OrganizationEventProvider>
              <Vision />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].tickets
          }
          element={
            <OrganizationEventProvider>
              <Tickets />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].earning
              .root
          }
          element={
            <OrganizationEventProvider>
              <EventEarningProvider>
                <StripeConnectionProvider>
                  <Earning />
                </StripeConnectionProvider>
              </EventEarningProvider>
            </OrganizationEventProvider>
          }
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].earning
              .connection[':stripeAccountId'].connected
          }
          element={
            <OrganizationEventProvider>
              <StripeConnectedCallbackHandler />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].addons}
          element={
            <OrganizationEventProvider>
              <Addons />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={
            routes.organization[':organizationId'].event[':eventId'].settings
          }
          element={
            <OrganizationEventProvider>
              <Settings />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].help}
          element={
            <OrganizationEventProvider>
              <Help />
            </OrganizationEventProvider>
          }
        />

        <Route
          path={routes.organization[':organizationId'].event[':eventId'].root}
          element={
            <OrganizationEventProvider>
              <EventIndex />
            </OrganizationEventProvider>
          }
        />

        <Route
          path="*"
          element={<Navigate to={routes.organization.list} replace />}
        />
      </Routes>
    </OrganizationProvider>
  )
}

export function useOrganizationRoutes() {
  const id = useParamOrganizationId()
  return routesWithValue(
    ':organizationId',
    String(id),
    routes.organization[':organizationId'],
  )
}

export function useParamOrganizationId() {
  const {organizationId} = useParams<{organizationId: string}>()
  return organizationId
}

export function useOrganizationEventRoutes() {
  const id = useParamEventId()
  const routes = useOrganizationRoutes()
  return routesWithValue(':eventId', String(id), routes.event[':eventId'])
}

export function useParamEventId() {
  const {eventId} = useParams<{eventId: string}>()
  return eventId
}

export function organizationRoutes(organization: Pick<Organization, 'id'>) {
  return routesWithValue(
    ':organizationId',
    String(organization.id),
    routes.organization[':organizationId'],
  )
}

export function organizationEventRoutes(
  event: Pick<EventModel, 'id'>,
  organizationRoutes: any,
) {
  return routesWithValue(
    ':eventId',
    String(event.id),
    organizationRoutes.event[':eventId'],
  )
}

export function organizationEventAttendeeRoutes(
  attendant: Pick<Attendant, 'id'>,
  organizationEventRoutes: any,
) {
  return routesWithValue(
    ':attendantId',
    String(attendant.id),
    organizationEventRoutes.attendant[':attendantId'],
  )
}
