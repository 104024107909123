import {useFormik} from 'formik'
import EventPage from 'organization/Event/EventPage'
import {UpdateMeetingData} from 'organization/Event/Management/UpdateMeetingData'
import {useMeeting} from 'organization/Event/MeetingProvider'
import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import BreadcrumMenu from 'organization/theme/Breadcrum'
import React from 'react'
import {toast} from 'react-toastify'
import styled from 'styled-components'

export function Management() {
  const {updateMeeting, meeting, getStartLink, loading} = useMeeting()
  const {event} = useOrganizationEvent()

  const {handleSubmit, handleBlur, handleChange, values} = useFormik({
    initialValues: {
      host_first_name: meeting?.host_first_name ?? '',
      host_last_name: meeting?.host_last_name ?? '',
      can_join: meeting?.can_join ?? false,
    },
    onSubmit: async (values: UpdateMeetingData) => {
      const result = await updateMeeting(values)

      if (result === true) {
        toast.success('Запазено')
      }
    },
  })

  const handleJoin = async () => {
    const result = await getStartLink()
    if (result) {
      window.open(result, '_blank', 'noopener,noreferrer')
    }
  }

  return (
    <EventPage>
      <>
        {!event?.is_public && (
          <NoActive>Ще бъде активно при публичен достъп</NoActive>
        )}
        <div className="row pl-30 pb-30 pr-30">
          <BreadcrumMenu title="Управление" />

          <div className="col-xxl-12">
            <div className="create__event-area">
              <div className="body__card-wrapper">
                {/* create event form  */}
                <form onSubmit={handleSubmit}>
                  <div className="create__event-main pt-25">
                    <div className="event__left-box">
                      <div className="create__input-wrapper">
                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Име на домакина
                          </label>

                          <input
                            type="text"
                            name="host_first_name"
                            placeholder="Въведете име ..."
                            defaultValue={values.host_first_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Фамилия на домакина
                          </label>

                          <input
                            type="text"
                            name="host_last_name"
                            placeholder="Въведете фамилия ..."
                            defaultValue={values.host_last_name}
                            onChange={handleChange}
                            onBlur={handleBlur}
                            required
                          />
                        </div>

                        <div className="singel__input-field mb-15">
                          <label className="input__field-text">
                            Участниците могат да се присъединят
                          </label>

                          <div className={`bd-theme-toggle `}>
                            <label
                              className="bd-theme-toggle-main"
                              style={{width: '100%'}}
                              htmlFor="bd-theme-toggler"
                            >
                              <span className={`bd-theme-toggle-dark `}>
                                Активно
                              </span>
                              <input
                                name="can_join"
                                type="checkbox"
                                id="bd-theme-toggler"
                                checked={values.can_join}
                                onChange={handleChange}
                                onBlur={handleBlur}
                              />
                              <i className="bd-theme-toggle-slide"></i>
                              <span className={`bd-theme-toggle-light `}>
                                Неактивно
                              </span>
                            </label>
                          </div>
                        </div>

                        <button
                          type="submit"
                          disabled={loading}
                          className={
                            loading
                              ? 'disableBtnStyle w-100 mt-30'
                              : 'input__btn w-100 mt-30'
                          }
                        >
                          Запазване
                        </button>
                      </div>
                    </div>
                    <div className="event__right-box">
                      <div className="row g-20">
                        <div className="pricing__item mb-30">
                          <div className="pricing__item-header">
                            <div>
                              <span className="pricing__item-title">
                                Стартиране на събитие
                              </span>
                            </div>
                          </div>
                          <div className="pricing__item-content">
                            <ul>
                              <li>
                                <i className="flaticon-warning-1"></i> Събитиети
                                ще бъде стартирано
                              </li>
                              <li>
                                <i className="flaticon-warning-1"></i>{' '}
                                Участниците ще могат да се присъединят ако
                                присъединяването е активно
                              </li>
                              <li>
                                <i className="flaticon-warning-1"></i>{' '}
                                Участниците ще Ви виждат с имената вписани в
                                "Имена на домакин"
                              </li>
                              <li>
                                <i className="flaticon-warning-1"></i>
                                Препоръчителни стъпки преди да активирате
                                присъединяване на участници:
                                <br /> 1. Уверете се, че всички микрофони и
                                камери работят.
                                <br /> 2. Добавете необходимите notes,
                                whiteboards, polls и т.н.
                                <br /> 3. Стартирайте "Cloud Recording", ако
                                искате събитието да бъде записано.
                              </li>
                              <li>
                                <i className="flaticon-warning-1"></i>{' '}
                                Препоръчителни настройки на Host Tools:
                                <br /> Деактивиране на всичкo освен "Chat" и
                                "Start Video"
                              </li>
                            </ul>
                          </div>
                          <div className="pricing__btn-wrap">
                            <JoinButton
                              type="button"
                              onClick={handleJoin}
                              disabled={loading}
                              className={
                                loading
                                  ? 'disableBtnStyle w-100 mt-30'
                                  : 'input__btn w-100 mt-30'
                              }
                            >
                              Стартиране
                            </JoinButton>
                          </div>
                        </div>
                      </div>
                    </div>
                  </div>
                </form>
              </div>
            </div>
          </div>
        </div>
      </>
    </EventPage>
  )
}

export const JoinButton = styled.button`
  background: linear-gradient(90deg, #05a95c, #04fc83);

  &.disableBtnStyle {
    background: #2e353a;
  }
`

const NoActive = styled.div`
  position: absolute;
  width: 100%;
  background: rgb(0 0 0 / 60%);
  height: 100%;
  z-index: 10;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 2rem;
  color: white;
  font-weight: bold;
  min-height: calc(100vh - 75px);
`
