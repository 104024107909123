import {publicImageUrl} from 'organization/ImageSelector'
import React from 'react'
import styled from 'styled-components'
import {Section} from 'TemplateEditor/template'

export function DefaultSectionHolder(props: {
  children: JSX.Element
  section: Section
  key: number | string
}) {
  return (
    <Holder section={props.section}>
      <StyledContainer section={props.section} className="container">
        {props.children}
      </StyledContainer>
    </Holder>
  )
}

const StyledContainer = styled.div<{section: Section}>`
  display: flex;
  justify-content: space-around;
  padding: 0;

  padding: ${(props) => props.section.padding.top}px
    ${(props) => props.section.padding.right}px
    ${(props) => props.section.padding.bottom}px
    ${(props) => props.section.padding.left}px;

  @container (max-width: 991px) {
    flex-direction: ${(props) =>
      props.section.revertMobile ? 'column-reverse' : 'column'};
  }
`

const Holder = styled.div<{section: Section}>`
  width: 100%;

  background: ${(props) => props.section.background ?? '#fffff'};
  background-image: url(${(props) =>
    props.section.backgroundImage
      ? publicImageUrl(props.section.backgroundImage)
      : ''});
  background-position: center;
  background-size: cover;

  ${(props) =>
    props.section.background
      ? `box-shadow: ${props.section.background}9f 0px 0px 0px 100cqh inset;;`
      : ''}

  border-color: ${(props) => props.section.border.color ?? '#ffffff'};
  border-width: ${(props) => props.section.border.width}px;
  border-radius: ${(props) => props.section.border.radius}px;
  border-style: ${(props) =>
    props.section.border.width ? 'solid' : 'initial'};
`
