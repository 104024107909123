import React from 'react'
import styled from 'styled-components'
import {PaddingInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/PaddingInput'
import {Padding} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export type DefaultBlockMobileConfigType = {
  padding: Padding
}

export const DefaultBlockMobileConfigValues: DefaultBlockMobileConfigType = {
  padding: {
    top: 0,
    bottom: 0,
    left: 0,
    right: 0,
  },
}

export function DefaultBlockMobileConfig(props: {
  values: any
  setFieldValue: any
}) {
  const {values, setFieldValue} = props

  return (
    <>
      <StyledTitle>Отстояния</StyledTitle>

      <PaddingInput
        value={values.mobile.padding}
        name={'mobile.padding'}
        label="Отстояния"
        setFieldValue={setFieldValue}
        max={500}
        min={0}
      />
    </>
  )
}

export const StyledTitle = styled.div`
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  color: var(--clr-common-white);
  background: var(--clr-gradient-1);
`
