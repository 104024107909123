import React, {useState} from 'react'
import LibraryAddIcon from '@mui/icons-material/LibraryAdd'
import ExampleTemplatesProvider from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplatesProvider'
import ExampleTemplateModal from 'TemplateEditor/Layout/Sidebar/ExampleTemplates/ExampleTemplateModal'
import styled from 'styled-components'

export function ExampleTemplates() {
  const [isOpen, setIsOpen] = useState<boolean>(false)

  const toggleOpen = () => {
    setIsOpen((cur) => !cur)
  }

  return (
    <>
      <StyledButton
        type="button"
        className={'input__btn w-100 mt-30'}
        onClick={toggleOpen}
      >
        <LibraryAddIcon /> Примерни дизайни
      </StyledButton>
      {isOpen && (
        <>
          <ExampleTemplatesProvider>
            <ExampleTemplateModal isOpen={isOpen} toggleOpen={toggleOpen} />
          </ExampleTemplatesProvider>
        </>
      )}
    </>
  )
}

const StyledButton = styled.button`
  position: fixed;
  bottom: 0px;
  width: 350px !important;
  border-radius: 0;
  right: 0px;
`
