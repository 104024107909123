import {EventModel} from 'event/models/Event'
import {useParams} from 'react-router-dom'
import {routes, routesWithValue} from 'routes/routes'

export function useParamEventSlug() {
  const {eventSlug} = useParams<{eventSlug: string}>()
  return eventSlug
}

export function useEventRoutes() {
  const slug = useParamEventSlug()
  return routesWithValue(':eventSlug', String(slug), routes[':eventSlug'])
}

export function eventRoutes(event: Pick<EventModel, 'slug'>) {
  return routesWithValue(':eventSlug', String(event.slug), routes[':eventSlug'])
}
