import {Loader} from 'attendee/theme/Loader'
import React from 'react'

export function BasicLoader() {
  return (
    <div id="preloader">
      <Loader />
    </div>
  )
}
