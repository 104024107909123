import React, {useEffect, useState} from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {SectionsList} from 'TemplateEditor/Layout/Sidebar/SectionsList'
import {BlocksList} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import EditIcon from '@mui/icons-material/Edit'
import VisibilityIcon from '@mui/icons-material/Visibility'
import ViewDayIcon from '@mui/icons-material/ViewDay'
import WidgetsIcon from '@mui/icons-material/Widgets'
import {ExampleTemplates} from 'TemplateEditor/Layout/Sidebar/ExampleTemplates'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'

export function Sidebar() {
  const {editMode, setEditMode, mobileMode, setMobileMode, setKeepSidebar} =
    useEditor()

  useEffect(() => {
    setKeepSidebar(true)
  }, [])

  type Tabs = 'sections' | 'elements'

  const getLastTab = (): Tabs => {
    return (localStorage.getItem('lastEditorTab') as Tabs) ?? 'sections'
  }

  const [tab, setTab] = useState<Tabs>(getLastTab())

  const handleTabChange = (tab: Tabs) => {
    setTab(tab)
    saveLastTab(tab)
  }

  const saveLastTab = (tab: Tabs) => {
    localStorage.setItem('lastEditorTab', tab)
  }

  const handleToggleEditMode = () => setEditMode(!editMode)
  const handleToggleMobileMode = () => setMobileMode(!mobileMode)
  return (
    <>
      <div className={'offcanvas__editor info-open'}>
        <div className="offcanvas__wrapper">
          <div
            className="offcanvas__content"
            style={{
              position: 'fixed',
              overflow: 'scroll',
              bottom: '0',
              top: '100px',
              width: '290px',
            }}
          >
            <div className="accordion__tab">
              <nav>
                <div className={`bd-theme-toggle mb-20 d-block`}>
                  <label
                    className="bd-theme-toggle-main"
                    style={{width: '100%'}}
                    htmlFor="bd-theme-toggler"
                  >
                    <span
                      className={`bd-theme-toggle-dark ${editMode ? 'active' : ''}`}
                    >
                      <EditIcon /> Редакция
                    </span>
                    <input
                      type="checkbox"
                      id="bd-theme-toggler"
                      checked={editMode}
                      onChange={handleToggleEditMode}
                    />
                    <i className="bd-theme-toggle-slide"></i>
                    <span
                      className={`bd-theme-toggle-light ${!editMode ? 'active' : ''}`}
                    >
                      <VisibilityIcon /> Преглед
                    </span>
                  </label>
                </div>

                <div className={`bd-theme-toggle mb-20 d-block`}>
                  <label
                    className="bd-theme-toggle-main"
                    style={{width: '100%'}}
                    htmlFor="bd-theme-toggler2"
                  >
                    <span
                      className={`bd-theme-toggle-dark ${!mobileMode ? 'active' : ''}`}
                    >
                      <LaptopMacIcon /> Стандартна
                    </span>
                    <input
                      type="checkbox"
                      id="bd-theme-toggler2"
                      checked={!mobileMode}
                      onChange={handleToggleMobileMode}
                    />
                    <i className="bd-theme-toggle-slide"></i>
                    <span
                      className={`bd-theme-toggle-light ${mobileMode ? 'active' : ''}`}
                    >
                      <SmartphoneIcon /> Мобилна
                    </span>
                  </label>
                </div>

                <div
                  className="nav nav-tabs mb-4"
                  style={{justifyContent: 'space-between'}}
                >
                  <button
                    className={`nav-link ${tab === 'sections' ? 'active' : ''}`}
                    type="button"
                    style={{
                      width: 'calc(50% - 10px)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 5,
                    }}
                    onClick={() => handleTabChange('sections')}
                  >
                    <ViewDayIcon /> Секции
                  </button>
                  <button
                    className={`nav-link ${tab === 'elements' ? 'active' : ''}`}
                    data-bs-toggle="tab"
                    data-bs-target="#editor-tab-blocks"
                    type="button"
                    role="tab"
                    style={{
                      width: 'calc(50% - 10px)',
                      display: 'flex',
                      justifyContent: 'center',
                      alignItems: 'center',
                      gap: 5,
                    }}
                    onClick={() => handleTabChange('elements')}
                  >
                    <WidgetsIcon /> Елементи
                  </button>
                </div>
              </nav>
            </div>

            <div className="tab-content" id="nav-tabContent">
              <div
                className={`tab-pane ${tab === 'sections' ? 'show active' : ''}`}
                id="editor-tab-sections"
                role="tabpanel"
                tabIndex={0}
              >
                <SectionsList />
                <ExampleTemplates />
              </div>

              <div
                className={`tab-pane ${tab === 'elements' ? 'show active' : ''}`}
                id="editor-tab-blocks"
                role="tabpanel"
                tabIndex={1}
              >
                <BlocksList />
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  )
}
