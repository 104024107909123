import {AddCardForm} from 'attendee/Billing/AddCardForm'
import {PaymentMethods} from 'attendee/Billing/PaymentMethods'
import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'
import {Loader} from 'attendee/theme/Loader'
import React, {useEffect, useState} from 'react'

export function Pay() {
  const {paymentMethods, getPaymentMethods, selectPaymentMethod, loading} =
    useAttendeeBilling()
  const [showAddForm, setShowAddForm] = useState<boolean>(false)

  useEffect(() => {
    getPaymentMethods()
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [])

  if (loading) {
    return <Loader />
  }

  return (
    <div>
      {paymentMethods.length > 0 && (
        <PaymentMethods onSelect={selectPaymentMethod} selectLabel="Избери" />
      )}
      {(showAddForm || paymentMethods.length <= 0) && <AddCardForm />}
      {!showAddForm && paymentMethods.length > 0 && (
        <button
          className="element__btn red-bg w-100 text-center mt-15"
          onClick={() => {
            setShowAddForm(true)
          }}
        >
          Добави друг начин на плащане{' '}
          <i className="fa-regular fa-angle-right"></i>
        </button>
      )}
    </div>
  )
}
