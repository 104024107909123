import React, {useEffect, useState} from 'react'
import {EventModel} from 'event/models/Event'
import {attendeeApiClient} from 'attendee/apiClient'
import {FullPageLoader} from 'attendee/theme/FullPageLoader'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {useParamEventSlug} from 'event/EventRoutes'
import {toast} from 'react-toastify'

export interface EventContextProps {
  event: EventModel | null
}

export const EventContext = React.createContext<EventContextProps | undefined>(
  undefined,
)

export default function EventProvider(props: {children: React.ReactElement}) {
  const apiClient = attendeeApiClient()
  const [event, setEvent] = useState<EventModel | null>(null)
  const [loading, setLoading] = useState<boolean>(true)
  const {user} = useAuth()
  const eventSlug = useParamEventSlug()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(
        `api/event/${eventSlug}${user ? '/loged' : ''}`,
      )
      setEvent(response)
      setLoading(false)
    } catch (error: any) {
      toast.warning(error.response.data.message)
      setLoading(false)
    }
  }
  return (
    <EventContext.Provider
      value={{
        event,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </EventContext.Provider>
  )
}

export function useEvent() {
  const context = React.useContext(EventContext)
  if (context === undefined) {
    throw new Error('useEvent must be used within EventProvider')
  }
  return context
}
