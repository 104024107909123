import React from 'react'

import AuthProvider from 'attendee/Auth/Providers/AuthProvider'
import {OrganizationRoutes} from 'organization/OrganizationRoutes'
import OrganizationAuthProvider from 'organization/Auth/Providers/OrganizationAuthProvider'
import ThemeProvider from 'attendee/theme/ThemeProvider'
import OrganizationThemeProvider from 'organization/theme/OrganizationThemeProvider'
import {useIsInOrganizationRoutes} from 'utils/lib/url'
import OrganizationBillingProvider from 'organization/Billing/Providers/OrganizationBillingProvider'
import AttendeeBillingProvider from 'attendee/Billing/Providers/AttendeeBillingProvider'
import {PublicRoutes} from 'attendee/PublicRoutes'
import {GoogleOneTab} from 'attendee/Auth/GoogleOneTab'

export const routes = createRoutes({
  index: '/',
  autologin: '/autologin',
  search: '/search',
  info: '/info',
  help: '/help',
  tickets: '/tickets',
  login: '/login',
  profile: '/profile',
  auth: {
    google: '/google',
  },
  register: '/register',
  passwordRecover: '/password-recover',
  billing: '/billing',
  organization: {
    autologin: '/autologin',
    login: '/login',
    register: '/register',
    passwordRecover: '/password-recover',
    profile: '/profile',
    billing: '/billing',
    auth: {
      google: '/google',
    },
    list: '/list',
    ':organizationId': {
      events: '/events',
      event: {
        create: {},
        ':eventId': {
          attendants: '/attendants',
          management: '/management',
          attendant: {
            ':attendantId': {
              view: '/view',
            },
          },
          page: '/page',
          settings: '/settings',
          tickets: '/tickets',
          earning: {
            connection: {
              ':stripeAccountId': {
                connected: '/connected',
              },
            },
          },
          addons: '/addons',
          vision: '/vision',
          help: '/help',
        },
      },
    },
  },
  ':eventSlug': {
    preview: '/preview',
  },
})

export function AllRoutes() {
  const isInOrganizationRoutes = useIsInOrganizationRoutes()

  if (isInOrganizationRoutes) {
    return (
      <OrganizationThemeProvider>
        <OrganizationAuthProvider>
          <OrganizationBillingProvider>
            <OrganizationRoutes />
          </OrganizationBillingProvider>
        </OrganizationAuthProvider>
      </OrganizationThemeProvider>
    )
  }

  return (
    <>
      <AuthProvider>
        <AttendeeBillingProvider>
          <ThemeProvider>
            <>
              <GoogleOneTab />
              <PublicRoutes />
            </>
          </ThemeProvider>
        </AttendeeBillingProvider>
      </AuthProvider>
    </>
  )
}

export type RoutesType = {
  [key: string]: string | RoutesType
}

type ExtendRecursively<O, T> = O extends object
  ? T & {[K in keyof O]: ExtendRecursively<O[K], T>}
  : O

function createRoutes<T extends RoutesType>(
  routes: T,
  namespace?: string,
): ExtendRecursively<T, {root: string}> {
  const childRoutes = Object.entries(routes).reduce((acc, [key, val]) => {
    if (typeof val === 'string') {
      const prependedVal = namespace ? `/${namespace}${val}` : val
      acc[key] = prependedVal
      return acc
    }

    const prependedKey = namespace ? `${namespace}/${key}` : key
    acc[key] = createRoutes(val, prependedKey)

    return acc
  }, {} as any)

  // append root
  childRoutes.root = namespace ? `/${namespace}` : '/'

  return childRoutes
}

export function routesWithValue<T>(param: string, value: string, routes: T): T {
  // @ts-ignore
  return Object.entries(routes).reduce((acc, [key, route]) => {
    if (typeof route === 'string') {
      const withParam = route.replace(param, value)
      acc[key] = withParam
      return acc
    }

    acc[key] = routesWithValue(param, value, route)
    return acc
  }, {} as any)
}
