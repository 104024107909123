import {Organization} from 'organization/models/Organization'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationId} from 'utils/lib/url'

export interface OrganizationContextProps {
  organization: Organization | null
}

export const OrganizationContext = React.createContext<
  OrganizationContextProps | undefined
>(undefined)

export default function OrganizationProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [organization, setOrganization] = useState<Organization | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  const organizationId = useRouteOrganizationId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(`api/organization/${organizationId}`)
      setOrganization(response)
      setLoading(false)
    } catch (error: any) {
      toast.warning(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <OrganizationContext.Provider
      value={{
        organization,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </OrganizationContext.Provider>
  )
}

export function useOrganization() {
  const context = React.useContext(OrganizationContext)
  if (context === undefined) {
    throw new Error('useOrganization must be used within OrganizationProvider')
  }
  return context
}
