import React, {useState} from 'react'
import {PaymentElement, useElements, useStripe} from '@stripe/react-stripe-js'
import {toast} from 'react-toastify'
import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'

export function AddCardForm() {
  const {createConfirmationIntent, getPaymentMethods, loading, setLoading} =
    useAttendeeBilling()
  const stripe = useStripe()
  const elements = useElements()

  const [errorMessage, setErrorMessage] = useState()

  const handleError = (error: any) => {
    setLoading(false)
    setErrorMessage(error.message)
  }

  const handleSubmit = async (event: any) => {
    // We don't want to let default form submission happen here,
    // which would refresh the page.
    event.preventDefault()

    if (!stripe || !elements) {
      // Stripe.js hasn't yet loaded.
      // Make sure to disable form submission until Stripe.js has loaded.
      return
    }

    // Trigger form validation and wallet collection
    const {error: submitError} = await elements.submit()
    if (submitError) {
      handleError(submitError)
      return
    }

    // Create the ConfirmationToken using the details collected by the Payment Element
    // and additional shipping information
    const {error, confirmationToken} = await stripe.createConfirmationToken({
      elements,
    })

    if (error) {
      // This point is only reached if there's an immediate error when
      // creating the ConfirmationToken. Show the error to your customer (for example, payment details incomplete)
      handleError(error)
      return
    }

    const response: any = await createConfirmationIntent(confirmationToken.id)

    if (response?.error) {
      toast.warn('Грешка при добавяне')
      // Show error from server in payment setup form
    } else if (response?.status === 'requires_action') {
      setLoading(true)
      // Use Stripe.js to handle the required next action
      const {error} = await stripe.handleNextAction({
        clientSecret: response?.client_secret,
      })

      if (error) {
        setLoading(false)
        toast.warn('Грешка при добавяне')
      } else {
        getPaymentMethods()
        toast.success('Успешно добавен начин на плащане.')
      }
    } else {
      getPaymentMethods()
      toast.success('Успешно добавен начин на плащане.')
    }

    setLoading(false)
  }

  return (
    <form onSubmit={handleSubmit}>
      <PaymentElement />
      <button
        type="submit"
        disabled={!stripe || loading}
        className="element__btn red-bg w-100 text-center mt-15"
      >
        Добави начин на плащане <i className="fa-regular fa-angle-right"></i>
      </button>
      {errorMessage && <div>{errorMessage}</div>}
    </form>
  )
}
