import React from 'react'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {Border} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function BorderInput(props: {
  value: Border
  setFieldValue: (field: string, value: Border) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const handleChange = (field: string, newValue: string | number) => {
    setFieldValue(name, {...value, [field]: newValue})
  }

  return (
    <>
      <ColorInput
        value={value.color ?? '#ffffff'}
        name={'color'}
        label="Цвят на границите"
        setFieldValue={handleChange}
      />

      <SliderInput
        value={value.width}
        name={'width'}
        label="Дебелина на границите"
        setFieldValue={handleChange}
        max={50}
        min={0}
        step={1}
      />

      <SliderInput
        value={value.radius}
        name={'radius'}
        label="Радиус на границите"
        setFieldValue={handleChange}
        max={100}
        min={0}
        step={1}
      />
    </>
  )
}
