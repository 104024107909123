import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import React, {ReactElement} from 'react'
import {Navigate} from 'react-router'
import {routes} from 'routes/routes'

export function AuthReqired(props: {children: ReactElement}) {
  const {user} = useAuth()

  if (!user) return <Navigate to={routes.login} replace />

  return props.children
}
