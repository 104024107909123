import React, {useEffect} from 'react'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import {useQueryParams} from 'utils/lib/url'
import {attendeeAccessTokenkey} from 'attendee/apiClient'
import {routes} from 'routes/routes'

export function Autologin() {
  const {token} = useQueryParams()

  useEffect(() => {
    localStorage.setItem(attendeeAccessTokenkey, token as any)
    window.location.href = routes.index
  }, [token])

  return <FullPageLoader />
}
