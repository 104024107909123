import React from 'react'
import styled from 'styled-components'
import {BorderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/BorderInput'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {PaddingInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/PaddingInput'
import {
  DefaultBlockMobileConfigType,
  DefaultBlockMobileConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockMobileConfig'

export type Padding = {
  top: number
  bottom: number
  left: number
  right: number
}

export type Border = {
  color: string | null
  width: number
  radius: number
}

export type Position = {
  vertical:
    | 'center'
    | 'end'
    | 'start'
    | 'space-evenly'
    | 'space-between'
    | 'space-around'
  horizontal: 'center' | 'end' | 'start'
}

export type TextAlign = 'left' | 'right' | 'center' | 'justify'

export type DefaultBlockConfigType = {
  padding: Padding
  background: string | null
  visible: boolean
  visibility: 'all' | 'desktop' | 'mobile'
  border: Border
  mobile: DefaultBlockMobileConfigType
}

export const DefaultBlockConfigValues = (): DefaultBlockConfigType => {
  return {
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    background: null,
    visible: false,
    visibility: 'all',
    border: {
      color: '#ffffff',
      width: 0,
      radius: 0,
    },
    mobile: {...DefaultBlockMobileConfigValues},
  }
}

export function DefaultBlockConfig(props: {values: any; setFieldValue: any}) {
  const {values, setFieldValue} = props

  return (
    <>
      <StyledTitle>Граници</StyledTitle>

      <BorderInput
        value={values.border}
        name={'border'}
        label="Граници"
        setFieldValue={setFieldValue}
      />

      <StyledTitle>Фон</StyledTitle>
      <ColorInput
        value={values.background ?? ''}
        name={'background'}
        label="Цвят на фона"
        setFieldValue={setFieldValue}
      />
      <StyledTitle>Отстояния</StyledTitle>

      <PaddingInput
        value={values.padding}
        name={'padding'}
        label="Отстояния"
        setFieldValue={setFieldValue}
        max={500}
        min={0}
      />
    </>
  )
}

export const StyledTitle = styled.div`
  font-weight: bold;
  padding: 5px;
  border-radius: 5px;
  margin-bottom: 15px;
  color: var(--clr-common-white);
  background: var(--clr-gradient-1);
`
