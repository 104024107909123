import Page from 'attendee/theme/Page'
import {useEventPreview} from 'event/providers/EventPreviewProvider'
import moment from 'moment'
import React, {useEffect, useState} from 'react'
import styled from 'styled-components'

export function WaitPage() {
  const {event} = useEventPreview()

  const [remainingTime, setRemainingTime] = useState<null | {
    days: number
    hours: number
    minutes: number
    seconds: number
  }>(null)

  const calculateRemainingTime = () => {
    const currentDate = moment(new Date())
    const targetDate = moment(event?.page_date)

    var duration = moment.duration(targetDate.diff(currentDate))

    const days = duration.days()
    const hours = duration.hours()
    const minutes = duration.minutes()
    const seconds = duration.seconds()

    if (duration.asMilliseconds() < 0) {
      window.location.reload()
    }
    if (duration.isValid()) {
      setRemainingTime({
        days,
        hours,
        minutes,
        seconds,
      })
    } else {
      setRemainingTime({
        days: 0,
        hours: 0,
        minutes: 0,
        seconds: 0,
      })
    }
  }
  useEffect(() => {
    calculateRemainingTime()
    const intervalId = setInterval(calculateRemainingTime, 1000)
    return () => {
      clearInterval(intervalId)
    }
  }, [])

  if (!event) return null

  return (
    <Page>
      <section className="event__schedule-area dark_light_class pt-55 pb-150">
        <div className="container">
          <div className="row align-items-center">
            <TimerTitle className="banner__title">
              <div className="text__highlight">
                Страницата на събитието <br /> ще бъде активнa след
              </div>
            </TimerTitle>

            <div className="banner__time">
              <CountDown className="count-down">
                {remainingTime && remainingTime.days}
                <span>Дена</span>
              </CountDown>
              <CountDown className="count-down">
                {remainingTime && remainingTime.hours}
                <span>Часа</span>
              </CountDown>
              <CountDown className="count-down">
                {remainingTime && remainingTime.minutes}
                <span>Минути</span>
              </CountDown>
              <CountDown className="count-down">
                {remainingTime && remainingTime.seconds}
                <span>Секунди</span>
              </CountDown>
            </div>
          </div>

          <TextHolder>
            <span>
              Ако автоматично не бъдете пренасочен към страницата на събитието
              след изтичането на времето, моля презаредете страницата.
            </span>
          </TextHolder>
        </div>
      </section>
    </Page>
  )
}

const TextHolder = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;

  & > span {
    max-width: 620px;
    text-align: center;
  }
`

const CountDown = styled.div`
  & > span {
    font-size: 20px;
  }
`

const TimerTitle = styled.h3`
  font-size: 40px;
  text-align: center;

  @media (max-width: 575px),
    only screen and (min-width: 576px) and (max-width: 767px),
    only screen and (min-width: 768px) and (max-width: 991px) {
    font-size: 30px;
  }
`
