import {Pay} from 'attendee/Billing/Pay'
import {useAttendeeBilling} from 'attendee/Billing/Providers/AttendeeBillingProvider'
import React from 'react'
import styled from 'styled-components'

export default function PayModal() {
  const {payModalOpen, setPayModalOpen, isFreePayment} = useAttendeeBilling()

  const handleOpenModal = () => {
    setPayModalOpen(!payModalOpen)
  }

  return (
    <>
      <div
        onClick={handleOpenModal}
        className={`offcanvas__overlay ${payModalOpen ? 'overlay-open' : ''}`}
      ></div>
      <section className={`speaker__popup-area ${payModalOpen ? 'open' : ''}`}>
        <div className="popup__wrapper">
          <div className="popup__title-wrapper">
            <h3 className="popup__title">
              {Boolean(isFreePayment) && <>Изберете карта за верификация</>}
              {!Boolean(isFreePayment) && <>Изберете начин на плащане</>}
            </h3>
          </div>

          <div className="popup__input-wrapper">
            {Boolean(isFreePayment) && (
              <VerificationText>
                Картата <b>няма</b> да бъде таксувана. <br /> Ще се използва с
                цел верификация.
              </VerificationText>
            )}
            {payModalOpen && <Pay />}
          </div>
        </div>
      </section>
    </>
  )
}

const VerificationText = styled.div`
  display: block;
  width: 100%;
  margin-bottom: 45px;
  text-align: center;
  font-size: 1.1rem;
`
