import usePayment from 'organization/Billing/Payments/usePayment'
import {
  useOrganizationRoutes,
  useParamOrganizationId,
} from 'organization/OrganizationRoutes'
import {useNavigate} from 'react-router-dom'

export default function useNewEventPayment() {
  const {newPayment} = usePayment()
  const navigate = useNavigate()
  const organizationId = useParamOrganizationId()
  const routes = useOrganizationRoutes()
  const onSuccess = () => {
    navigate(routes.events)
  }

  const payment = (requestData: {[x: string]: any}) => {
    return newPayment(
      `api/payment/organization/${organizationId}/event/buy`,
      `api/payment/organization/${organizationId}/event/confirm-buy`,
      requestData,
      onSuccess,
    )
  }

  return {
    payment,
  }
}
