import {BlockElements, ListBlocks} from 'TemplateEditor/Layout/Blocks'
import {SectionConfigType} from 'TemplateEditor/Layout/Sections/SectionConfig'
import {SubSectionConfigType} from 'TemplateEditor/Layout/Sections/SubSectionConfig'

export type Template = {
  sections?: Section[]
}

const allTypes = ListBlocks.map((ListBlock) => ListBlock.typeId)
export type BlockTypes = (typeof allTypes)[number]

export type Section = SectionConfigType & {
  id: string
  type: string
  blocks: {
    [position: number]: BlockElements[]
  }
  innerStyles: {
    [position: number]: SubSectionConfigType
  }
}

export type Block = {
  id: string
  type: BlockTypes
}

export const DefaultTemplate: Template = {
  sections: [],
}
