import React from 'react'
import {BlockDrop} from 'TemplateEditor/Layout/Sections/BlockDrop'
import {DefaultSectionHolder} from 'TemplateEditor/Layout/Sections/DefaultSectionHolder'
import {ResponsiveSection} from 'TemplateEditor/Layout/Sections/ResponsiveSection'
import {
  defaultSectionConfig,
  SectionProps,
} from 'TemplateEditor/Layout/Sections/Section'
import {subSectionDefauts} from 'TemplateEditor/Layout/Sections/SubSectionConfig'
import {
  PreviewSectionDiv,
  PreviewSectionHolderDiv,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

export const section211TypeId = 'section-2-1-1'

export function Section211(props: SectionProps) {
  const {section, sectionIndex, key} = props

  return (
    <DefaultSectionHolder section={section} key={key}>
      <>
        <ResponsiveSection
          width={50}
          section={section}
          index={1}
          sectionIndex={sectionIndex}
        >
          <BlockDrop section={section} index={1} sectionIndex={sectionIndex} />
        </ResponsiveSection>
        <ResponsiveSection
          width={25}
          section={section}
          index={2}
          sectionIndex={sectionIndex}
        >
          <BlockDrop section={section} index={2} sectionIndex={sectionIndex} />
        </ResponsiveSection>
        <ResponsiveSection
          width={25}
          section={section}
          index={3}
          sectionIndex={sectionIndex}
        >
          <BlockDrop section={section} index={3} sectionIndex={sectionIndex} />
        </ResponsiveSection>
      </>
    </DefaultSectionHolder>
  )
}

export function PreviewSection211() {
  return (
    <PreviewSectionHolderDiv>
      <PreviewSectionDiv style={{width: '50%'}}>50%</PreviewSectionDiv>
      <PreviewSectionDiv style={{width: '25%'}}>25%</PreviewSectionDiv>
      <PreviewSectionDiv style={{width: '25%'}}>25%</PreviewSectionDiv>
    </PreviewSectionHolderDiv>
  )
}

export function newSection211(): Section {
  return {
    ...defaultSectionConfig(),
    id: uuidv4(),
    type: section211TypeId,
    blocks: {},
    innerStyles: {
      1: subSectionDefauts(),
      2: subSectionDefauts(),
      3: subSectionDefauts(),
    },
  }
}
