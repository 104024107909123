import {EventModel} from 'event/models/Event'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'
import {useRouteOrganizationId} from 'utils/lib/url'

export interface OrganizationEventsListContextProps {
  events: EventModel[]
}

export const OrganizationEventsListContext = React.createContext<
  OrganizationEventsListContextProps | undefined
>(undefined)

export default function OrganizationEventsListProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [events, setEvents] = useState<EventModel[]>([])
  const [loading, setLoading] = useState<boolean>(true)

  const organizationId = useRouteOrganizationId()

  useEffect(() => {
    get()
  }, [])

  const get = async () => {
    try {
      const response = await apiClient.get(
        `api/organization/${organizationId}/events`,
      )
      setEvents(response)
      setLoading(false)
    } catch (error: any) {
      toast.warning(error.response.data.message)
      setLoading(false)
    }
  }

  return (
    <OrganizationEventsListContext.Provider
      value={{
        events,
      }}
    >
      {loading && <FullPageLoader />}
      {!loading && props.children}
    </OrganizationEventsListContext.Provider>
  )
}

export function useOrganizationEventsList() {
  const context = React.useContext(OrganizationEventsListContext)
  if (context === undefined) {
    throw new Error(
      'useOrganizationEventsList must be used within OrganizationEventsListProvider',
    )
  }
  return context
}
