import React from 'react'
import styled from 'styled-components'
import Stick1 from 'assets/custom_images/Sticks/Stick1.webp'
import Stick2 from 'assets/custom_images/Sticks/Stick2.webp'
import Stick3 from 'assets/custom_images/Sticks/Stick3.webp'
import Stick1PDF from 'assets/custom_images/Sticks/Stick1PDF.pdf'
import Stick2PDF from 'assets/custom_images/Sticks/Stick2PDF.pdf'
import Stick3PDF from 'assets/custom_images/Sticks/Stick3PDF.pdf'
import {Link} from 'react-router-dom'
import {routes} from 'routes/routes'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'

export const Sticks = () => {
  return (
    <>
      <Stick stick={Stick1} download={Stick1PDF} />
      <Stick stick={Stick2} download={Stick2PDF} />
      <Stick stick={Stick3} download={Stick3PDF} />
    </>
  )
}

const Stick = (props: {stick: string; download: string}) => {
  const {stick, download} = props
  const {user} = useAuth()
  return (
    <div key={1} className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-xs-12">
      <div
        className="event__venue-card mb-20"
        style={{
          borderBottom: '2px solid #f7426f',
          background: '#f5f5f5',
          borderRadius: 5,
          padding: 0,
        }}
      >
        <div className="event__venue-inner" style={{padding: 15}}>
          <div className="event__venue-thumb ">
            <ImageHolder $background={stick} />
          </div>
          <StickerInfo>
            <b>Инструкции + списък с необходими материали</b> <br />
            <br />
            Основни материали:
            <br /> Пръчица за сладолед и принтер
          </StickerInfo>
          {user && (
            <Link
              to={download}
              target="_blank"
              download
              className={'input__btn w-100 '}
            >
              Изтегли безплатно
            </Link>
          )}
          {!user && (
            <Link to={routes.login} className={'input__btn w-100 '}>
              Изтегли безплатно
            </Link>
          )}
        </div>
      </div>
    </div>
  )
}

const StickerInfo = styled.div`
  padding: 15px;
`

const ImageHolder = styled.div<{$background: string | undefined}>`
  background-image: url(${(props) => props.$background});
  width: calc(100% + 20px);
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  margin: -10px 0 0 -10px;
`
