import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

import FormatLineSpacingIcon from '@mui/icons-material/FormatLineSpacing'
import {useEditor} from 'TemplateEditor/EditorProvider'
import styled from 'styled-components'

export const SpacerTypeId = 'block-spacer'

export function Spacer(props: {
  block: SpacerConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  const {editMode} = useEditor()

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <StyledSpacer
        block={block}
        style={{
          background: editMode
            ? 'repeating-linear-gradient(135deg, #ffe4e4 0%, #ffe4e4 1%, transparent 1%, transparent 2%)'
            : '',
        }}
      />
    </DefaultBlockHolder>
  )
}

export function PreviewSpacer() {
  return (
    <PreviewHolderDiv>
      <FormatLineSpacingIcon />
      <span>Разделител</span>
    </PreviewHolderDiv>
  )
}

export type SpacerConfigType = Block &
  DefaultBlockConfigType & {
    height: number
    mobile: {
      height: number
    }
  }

export function newSpacer(): SpacerConfigType {
  return {
    ...DefaultBlockConfigValues(),
    id: uuidv4(),
    type: SpacerTypeId,
    height: 20,
    mobile: {
      ...DefaultBlockConfigValues().mobile,
      height: 20,
    },
  }
}

const StyledSpacer = styled.div<{block: SpacerConfigType}>`
  border-radius: ${(props) => props.block.border.radius}px;
  border-width: ${(props) => props.block.border.width}px;
  border-color: ${(props) => props.block.border.color};
  border-style: solid;
  height: ${(props) => props.block.height}px;
  width: 100%;

  @container (max-width: 991px) {
    height: ${(props) => props.block.mobile.height}px;
  }
`
