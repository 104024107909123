import {organizationApiClient} from 'organization/organizationApiClient'
import React, {useEffect, useState} from 'react'
import {useRouteOrganizationEventId} from 'utils/lib/url'

export type BalanceType = 'income' | 'expense'
export type BalanceFrom = 'ticket' | 'withdraw'

export type Balance = {
  type: BalanceType
  from: BalanceFrom
  id: number
  price: number
}

export interface EventEarningContextProps {
  totalProfit: number
  ticketSales: number
  platformTax: number
  balance: Balance[]
  loading: boolean
  page: number
  totalPages: number
  changePage: (e: any, page: number) => void
  search: (term: string) => void
}

export const EventEarningContext = React.createContext<
  EventEarningContextProps | undefined
>(undefined)

export default function EventEarningProvider(props: {
  children: React.ReactElement
}) {
  const apiClient = organizationApiClient()
  const [totalProfit, setTotalProfit] = useState<number>(0)
  const [ticketSales, setTicketSales] = useState<number>(0)
  const [platformTax, setPlatformTax] = useState<number>(0)
  const [loading, setLoading] = useState<boolean>(true)
  const [totalPages, setTotalPages] = useState<number>(1)
  const [page, setPage] = useState<number>(1)
  const [balance, setBalance] = useState<Balance[]>([])

  const eventId = useRouteOrganizationEventId()

  useEffect(() => {
    get()
  }, [])

  const get = async (getPage?: number, searchTerm?: string) => {
    const selectedPage = getPage ?? page
    const search = searchTerm ?? ''

    await apiClient
      .get(`api/event/${eventId}/earning?page=${selectedPage}&search=${search}`)
      .then((response) => {
        setTotalProfit(response.total_profit)
        setTicketSales(response.ticket_sales)
        setPlatformTax(response.platform_tax)
        setBalance(response.data)
        setTotalPages(response.last_page)
        setPage(response.current_page)
      })
      .finally(() => setLoading(false))
  }

  const changePage = (e: any, toPage: number) => {
    setPage(toPage)
    get(toPage)
  }

  const search = async (term: string) => {
    return get(1, term)
  }

  // const balance = [
  //   {
  //     type: 'income' as BalanceType,
  //     from: 'ticket' as BalanceFrom,
  //     id: 1,
  //     price: 20,
  //   },
  //   {
  //     type: 'expense' as BalanceType,
  //     from: 'withdraw' as BalanceFrom,
  //     id: 2,
  //     price: 20,
  //   },
  // ]

  return (
    <EventEarningContext.Provider
      value={{
        totalProfit,
        ticketSales,
        platformTax,
        balance,
        loading,
        page,
        changePage,
        totalPages,
        search,
      }}
    >
      {props.children}
    </EventEarningContext.Provider>
  )
}

export function useEventEarning() {
  const context = React.useContext(EventEarningContext)
  if (context === undefined) {
    throw new Error('useEventEarning must be used within EventEarningProvider')
  }
  return context
}
