import React from 'react'
import {EventModel, getEventImageUrl} from 'event/models/Event'
import {Link} from 'react-router-dom'
import moment from 'moment'
import 'moment/locale/bg'
import TrendingUpIcon from '@mui/icons-material/TrendingUp'
import EventSeatIcon from '@mui/icons-material/EventSeat'
import styled from 'styled-components'
import {publicImageUrl} from 'organization/ImageSelector'
import defaultEventImage from 'images/defaultEventImage.webp'
import {eventRoutes} from 'event/EventRoutes'
import {getTicketPrice} from 'event/ticketPrices'

export const EventListItem = (props: {event: EventModel; index: number}) => {
  const {event, index} = props

  const routes = eventRoutes(event)
  const eventImage = getEventImageUrl(event, 1)

  const background = eventImage ? publicImageUrl(eventImage) : defaultEventImage

  const eventPrice = event?.is_free
    ? 'Безплатно'
    : `${getTicketPrice(event)} лв.`

  return (
    <div
      key={index}
      className="col-xxl-4 col-xl-6 col-lg-6 col-md-12 col-xs-12"
    >
      <ItemHolder
        className="event__venue-card"
        style={{
          borderBottom: '2px solid #f7426f',
        }}
      >
        <ImageHolder $background={background} />

        <div className="event__venue-thumb ">
          <div className="event__tag-post">
            {!event.ended_at && (
              <div className="event__tag-item" style={{fontSize: 11}}>
                <span style={{background: '#ffc15b'}}>
                  <TrendingUpIcon fontSize={'small'} /> Набира популярност
                </span>
              </div>
            )}
            <div className="event__tag-item" style={{fontSize: 11}}>
              <span>
                <EventSeatIcon fontSize={'small'} />
                {event.available_seats} свободни места
              </span>
            </div>
          </div>
        </div>

        <NameHolder>
          {event.ended_at && <div style={{color: '#ffffff'}}>{event.name}</div>}
          {!event.ended_at && (
            <NameLink to={routes.root}>{event.name}</NameLink>
          )}
        </NameHolder>
        <ItemInternalHolder>
          <Description>{event.short_description}</Description>

          <div className="event__venue-meta">
            {!event.ended_at && (
              <DateHolder className="news__meta-status">
                <div>
                  <span className="mr-5">
                    <i className="flaticon-calendar-1"></i>
                  </span>
                  <span>
                    {event.date
                      ? moment(event.date).format('DD MMMM YYYY')
                      : 'Няма информация'}
                  </span>
                </div>
                <div>
                  <span className="mr-5">
                    <i className="flaticon-clock"></i>
                  </span>
                  <span>
                    {event.date
                      ? moment(event.date).format('HH:mm')
                      : 'Няма информация'}{' '}
                  </span>
                </div>
              </DateHolder>
            )}

            {event.ended_at && (
              <DateHolder className="news__meta-status">
                <div>
                  <span>ПРИКЛЮЧИЛО</span>
                </div>
              </DateHolder>
            )}
          </div>

          <div
            className="news__meta-status"
            style={{justifyContent: 'space-between', alignItems: 'center'}}
          >
            <PriceHolder>{eventPrice}</PriceHolder>
            <LabelHolder className="pricing__item-label">
              {event.ticket_name}
            </LabelHolder>
          </div>

          {event.ended_at && (
            <EndedButton type="button" className={'input__btn w-100 mt-30'}>
              Приключило
            </EndedButton>
          )}
          {!event.ended_at && (
            <Link to={routes.root} className={'input__btn w-100 mt-30'}>
              Преглед на събитието
            </Link>
          )}
        </ItemInternalHolder>
      </ItemHolder>
    </div>
  )
}

const ItemHolder = styled.div`
  padding: 0;
  margin: 30px 0;
  background: #f5f5f5;
`

const ItemInternalHolder = styled.div`
  padding: 15px;
`

const ImageHolder = styled.div<{$background: string | undefined}>`
  background-image: url(${(props) => props.$background});
  width: 100%;
  height: 200px;
  background-position: center;
  background-size: cover;
  border-radius: 6px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
`

const NameHolder = styled.h3`
  font-weight: bold;
  background: var(--clr-gradient-14);
  padding: 15px;
  font-size: 1rem;
`

const NameLink = styled(Link)`
  color: #ffffff;
  display: inline-block;
`

const Description = styled.div`
  display: inline-block;
  width: 100%;
  height: 55px;
  overflow: hidden;
  display: -webkit-box;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  color: #4f4f4f;
`

const DateHolder = styled.div`
  display: flex;
  width: 100%;
`

const PriceHolder = styled.span`
  background: linear-gradient(91deg, #f7426f 5.15%, #f87a58 99.88%);
  background-clip: text;
  font-size: 2rem !important;
  font-weight: bold;
  -webkit-text-fill-color: transparent;
`

const EndedButton = styled.button`
  background: var(--clr-text-primary);
  color: var(--clr-text-secondary);

  &:hover {
    color: var(--clr-text-secondary);
  }
`

const LabelHolder = styled.div``
