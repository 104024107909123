import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {TextAlignInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/TextAlignInput'
import {DefaultBlockMobileConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockMobileConfig'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'
import {NormalTextConfigType} from 'TemplateEditor/Layout/Blocks/Text/NormalText'

export function NormalTextMobileConfig() {
  const {handleSubmit, values, setFieldValue} =
    useFormicForm<NormalTextConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20 caveat-font'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SliderInput
          value={values.mobile.fontSize}
          name={'mobile.fontSize'}
          label="Големина на текста"
          setFieldValue={setFieldValue}
          max={8}
          min={0.5}
          step={0.1}
        />

        <TextAlignInput
          value={values.mobile.align}
          name={'mobile.align'}
          label="Подравняване /дълъг текст/"
          setFieldValue={setFieldValue}
        />

        <DefaultBlockMobileConfig
          values={values}
          setFieldValue={setFieldValue}
        />
      </form>
    </>
  )
}
