import React from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {BorderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/BorderInput'
import {PaddingInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/PaddingInput'
import {
  Border,
  Padding,
  Position,
  StyledTitle,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import SmartphoneIcon from '@mui/icons-material/Smartphone'
import LaptopMacIcon from '@mui/icons-material/LaptopMac'
import {PositionInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/PositionInput'
import {ColorInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ColorInput'
import {ImageInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/ImageInput'

export type SubSectionConfigType = {
  position: Position
  padding: Padding
  border: Border
  background: string | null
  backgroundImage: string | null
}

export const subSectionDefauts = (): SubSectionConfigType => {
  return {
    position: {
      vertical: 'center',
      horizontal: 'center',
    },
    padding: {
      top: 0,
      bottom: 0,
      left: 0,
      right: 0,
    },
    border: {
      color: null,
      width: 0,
      radius: 0,
    },
    background: null,
    backgroundImage: null,
  }
}

export function SubSectionConfig() {
  const {setEditElement, mobileMode, setMobileMode} = useEditor()

  const handleToggleMobileMode = () => setMobileMode(!mobileMode)

  const {handleSubmit, values, setFieldValue} =
    useFormicForm<SubSectionConfigType>()

  return (
    <>
      <div className={'offcanvas__editor info-open'}>
        <div className="offcanvas__wrapper">
          <div
            className="offcanvas__content"
            style={{
              overflow: 'scroll',
              bottom: '0',
              top: '100px',
              width: '290px',
            }}
          >
            <div className={`bd-theme-toggle mb-20 d-block`}>
              <label
                className="bd-theme-toggle-main"
                style={{width: '100%'}}
                htmlFor="bd-theme-toggler2"
              >
                <span
                  className={`bd-theme-toggle-dark ${!mobileMode ? 'active' : ''}`}
                >
                  <LaptopMacIcon /> Стандартна
                </span>
                <input
                  type="checkbox"
                  id="bd-theme-toggler2"
                  checked={!mobileMode}
                  onChange={handleToggleMobileMode}
                />
                <i className="bd-theme-toggle-slide"></i>
                <span
                  className={`bd-theme-toggle-light ${mobileMode ? 'active' : ''}`}
                >
                  <SmartphoneIcon /> Мобилна
                </span>
              </label>
            </div>

            <div style={{display: 'flex', gap: 10}}>
              <button
                className={'input__btn border-red w-100 mb-20'}
                style={{
                  background: 'none',
                  color: '#f87a58',
                  border: '1px solid #f87a58',
                  height: '35px',
                }}
                onClick={() => {
                  setEditElement(null)
                }}
              >
                {`Затвори`}
              </button>
            </div>

            <form onSubmit={handleSubmit}>
              <button className={'input__btn w-100 mb-20'} type="submit">
                Запазване
              </button>

              <ColorInput
                value={values.background ?? '#ffffff'}
                name={'background'}
                label="Цвят на фона"
                setFieldValue={setFieldValue}
              />

              <ImageInput
                value={values.backgroundImage}
                name={'backgroundImage'}
                label="Изображение на фона"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Съдържание</StyledTitle>

              <PositionInput
                value={values.position}
                name={'position'}
                label="Позиция"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Граници</StyledTitle>

              <BorderInput
                value={values.border}
                name={'border'}
                label="Граници"
                setFieldValue={setFieldValue}
              />

              <StyledTitle>Отстояния</StyledTitle>

              <PaddingInput
                value={values.padding}
                name={'padding'}
                label="Отстояния"
                setFieldValue={setFieldValue}
                max={500}
                min={0}
              />
            </form>
          </div>
        </div>
      </div>
    </>
  )
}
