import {Template} from 'TemplateEditor/template'
import {EventImage} from 'event/models/EventImages'
import {Organization} from 'organization/models/Organization'
import {Image} from 'organization/ImageSelector'
import {SaveEventData} from 'organization/Event/Settings/SaveEvent'
import {StripeConnection} from 'stripe/stripeConnection'

export interface EventModel {
  id: number
  name: string
  slug: string
  is_free: boolean
  short_description: string
  description: string
  video: string
  organization: Organization
  price: number
  ticket_name: string
  ticket_features: string
  total_seats: number
  available_seats: number
  date: Date
  page_date: Date
  template: Template
  images: EventImage[]
  is_public: boolean
  is_ended: boolean
  published_at: Date
  ended_at: Date
  paid?: boolean
  has_ticket?: boolean
  stripe_connection: StripeConnection | null
}

export function getEventImageUrl(event: EventModel | null, imageType: number) {
  const find = event?.images.find((image) => image.type_id === imageType)

  if (find) {
    return find.image.url
  }

  return null
}

export function getEventImage(event: EventModel | null, imageType: number) {
  const find = event?.images.find((image) => image.type_id === imageType)

  if (find) return find

  return null
}

export function updateEventImagesAgregate(
  images: SaveEventData['images'],
  imageType: number,
  updatedImage: Image | null,
) {
  if (!images) {
    return [
      {
        type_id: imageType,
        image_id: updatedImage?.id ?? null,
      },
    ]
  }

  const exists = images.find((image) => image.type_id === imageType)

  if (exists) {
    return images.map((updateImage) => {
      if (updateImage.type_id === imageType) {
        return {
          ...updateImage,
          image_id: updatedImage?.id ?? null,
        }
      }

      return updateImage
    })
  } else {
    images.push({
      type_id: imageType,
      image_id: updatedImage?.id ?? null,
    })
    return images
  }
}
