import overlay_img from 'images/loginb2.webp'
import {useFormik} from 'formik'
import React, {useState} from 'react'
import {toast} from 'react-toastify'
import {Link, useNavigate} from 'react-router-dom'
import 'assets/styles.scss'
import {signup_schema} from 'utils/validation-schema'
import {useOrganizationAuth} from 'organization/Auth/Providers/OrganizationAuthProvider'
import {routes} from 'routes/routes'
import {RegisterData} from 'organization/Auth/Interfaces/register'
import {PromotionHolder} from 'attendee'

const ErrorMsg = ({errors}: any) => {
  return (
    <>
      <p className="error-msg" style={{color: 'red'}}>
        {errors}
      </p>
    </>
  )
}

export function Register() {
  const {register} = useOrganizationAuth()
  const navigate = useNavigate()
  const [isBtnDisable, setBtnDisable] = useState(false)

  const {handleSubmit, handleBlur, handleChange, errors, touched, values} =
    useFormik({
      initialValues: {
        organization_name: '',
        first_name: '',
        last_name: '',
        email: '',
        password: '',
        password_confirmation: '',
      },
      validationSchema: signup_schema,
      onSubmit: async (values: RegisterData) => {
        setBtnDisable(true)
        const registerResult = await register(values)

        if (registerResult === true) {
          toast.success('Успешна регистрация')
          navigate(routes.organization.list)
        }

        setBtnDisable(false)
      },
    })

  return (
    <>
      <main className={`body-area bd-theme-light`}>
        <section className="signin__area">
          <div className="sign__main-wrapper">
            <div className="sign__left">
              <div className="sign__header">
                <div className="sign__logo">
                  <Link to="/">
                    <img
                      style={{width: '150px', height: 'auto'}}
                      className="logo-black"
                      src={process.env.PUBLIC_URL + '/logo-dark.svg'}
                      alt="logoblack"
                    />
                  </Link>
                </div>
                <div className="sign__link custome_link">
                  <Link
                    className="sign__link-text"
                    to={routes.organization.login}
                  >
                    Вход
                  </Link>
                  <Link
                    className="sign__link-active"
                    to={routes.organization.register}
                  >
                    Регистрация
                  </Link>
                </div>
              </div>
              <div className="sign__center-wrapper text-center mt-35">
                <PromotionHolder style={{marginBottom: 25}}>
                  <div>
                    Промоционална цена за създаване на събитие от{' '}
                    <span style={{textDecoration: 'line-through'}}>179</span>{' '}
                    лв. на <b style={{fontSize: '1.5rem'}}>1 лв.</b>
                  </div>
                </PromotionHolder>

                <div className="sign__title-wrapper mb-40">
                  <h3 className="sign__title">Регистрация на организация</h3>
                  <p>Създаване на член на организация</p>
                </div>
                <div className="sign__input-form text-center">
                  <form onSubmit={handleSubmit}>
                    <div className="sign__input">
                      <input
                        type="text"
                        name="organization_name"
                        value={values.organization_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Име на организацията"
                        required
                      />
                      <span>
                        <i className="flaticon-user-2"></i>
                      </span>
                      {touched.organization_name && (
                        <ErrorMsg errors={errors.organization_name} />
                      )}
                    </div>
                    <div className="sign__input">
                      <input
                        type="text"
                        name="first_name"
                        value={values.first_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Вашето Име"
                        required
                      />
                      <span>
                        <i className="flaticon-user-2"></i>
                      </span>
                      {touched.first_name && (
                        <ErrorMsg errors={errors.first_name} />
                      )}
                    </div>

                    <div className="sign__input">
                      <input
                        type="text"
                        name="last_name"
                        value={values.last_name}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Вашата Фамилия"
                        required
                      />
                      <span>
                        <i className="flaticon-user-2"></i>
                      </span>
                      {touched.last_name && (
                        <ErrorMsg errors={errors.last_name} />
                      )}
                    </div>
                    <div className="sign__input">
                      <input
                        type="email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Имейл адрес"
                        required
                      />
                      <span>
                        <i className="flaticon-email"></i>
                      </span>
                      {touched.email && <ErrorMsg errors={errors.email} />}
                    </div>
                    <div className="sign__input">
                      <input
                        type="password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Въведете парола"
                        required
                      />
                      <span>
                        <i className="flaticon-password"></i>
                      </span>
                      {touched.password && (
                        <ErrorMsg errors={errors.password} />
                      )}
                    </div>
                    <div className="sign__input">
                      <input
                        type="password"
                        name="password_confirmation"
                        value={values.password_confirmation}
                        onChange={handleChange}
                        onBlur={handleBlur}
                        placeholder="Потвърдете парола"
                        required
                      />
                      <span>
                        <i className="flaticon-password"></i>
                      </span>
                      {touched.password_confirmation && (
                        <ErrorMsg errors={errors.password_confirmation} />
                      )}
                    </div>
                    <div className="sing__button mb-20">
                      <button
                        type="submit"
                        disabled={isBtnDisable}
                        className={
                          isBtnDisable
                            ? 'disableBtnStyle w-100 mb-20'
                            : 'input__btn w-100 mb-20'
                        }
                      >
                        Регистрация
                      </button>
                    </div>
                  </form>
                  <div className="if__account mt-90">
                    <p>
                      Вече имате регистрация? <br />
                      <Link to={routes.organization.login}>Вход</Link>
                    </p>
                  </div>
                </div>
              </div>
            </div>
            <div className="sign__right">
              <div
                className="sign__input-thumb include__bg w-img"
                style={{
                  backgroundImage: `url(${overlay_img})`,
                  backgroundPosition: 'center',
                  backgroundSize: 'contain',
                }}
              ></div>
            </div>
          </div>
        </section>
      </main>
    </>
  )
}
