import {Ticket} from 'attendee/Tickets/Ticket'
import {eventRoutes} from 'event/EventRoutes'
import React from 'react'
import {Link} from 'react-router-dom'

export function EventListItem(props: {ticket: Ticket}) {
  const {ticket} = props
  const routes = eventRoutes(ticket.event)

  return (
    <tr key={1}>
      <td data-label="Номер">
        <div className="attendant__serial">
          <span> {ticket.id} </span>
        </div>
      </td>
      <td data-label="Цена">
        <div className="attendant__date">
          <span>{ticket.event.price} лв.</span>
        </div>
      </td>
      <td data-label="Събитие">
        <div>
          <span> {ticket.event.name} </span>
        </div>
      </td>
      <td>
        <div className="attendant__status">
          <span className={'status__tag bg-green'}>Платено</span>
        </div>
      </td>
      <td>
        <Link
          to={routes.preview}
          target="_blank"
          className="fill__btn w-100"
          style={{justifyContent: 'center'}}
        >
          Вход <i className="fa-regular fa-angle-right"></i>
        </Link>
      </td>
    </tr>
  )
}
