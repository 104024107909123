import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {useTemplate} from 'TemplateEditor/TemplateProvider'
import {Section} from 'TemplateEditor/Layout/Sections/Section'
import {useEditor} from 'TemplateEditor/EditorProvider'

export function Content() {
  const {template} = useTemplate()

  const {editMode, mobileMode} = useEditor()
  const Items = React.memo(() => (
    <>
      {template.sections?.length === 0 && editMode && (
        <NoSections>За да запознете плъзнете избраната секция тук </NoSections>
      )}

      {template.sections &&
        template.sections.map((section, index) => (
          <Draggable
            isDragDisabled={!editMode}
            key={index}
            draggableId={String(index)}
            index={index}
          >
            {(provided) => (
              <Section
                provided={provided}
                section={section}
                sectionIndex={index}
              />
            )}
          </Draggable>
        ))}
    </>
  ))
  return (
    <EditorPreview mobileMode={mobileMode}>
      <Droppable
        droppableId="editor-sections"
        type="section"
        isDropDisabled={!editMode}
      >
        {(provided) => (
          <StyledDroppable {...provided.droppableProps} ref={provided.innerRef}>
            <Items />

            {provided.placeholder}
          </StyledDroppable>
        )}
      </Droppable>
    </EditorPreview>
  )
}

const EditorPreview = styled.div<{mobileMode: boolean}>`
  box-sizing: border-box;
  position: relative;
  margin: 0 auto;
  width: 100%;

  height: 100%;
  transition: 0.2s;
`

const StyledDroppable = styled.div`
  min-height: calc(100vh - 76px);
`

const NoSections = styled.div`
  width: 100%;
  padding: 35px 0px;
  background: repeating-linear-gradient(
    135deg,
    #ffe4e4 0%,
    #ffe4e4 1%,
    transparent 1%,
    transparent 2%
  );
  text-align: center;
  border-bottom: 2px solid #f75b64;
  font-weight: bold;
`
