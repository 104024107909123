import {Loader} from 'attendee/theme/Loader'
import Page from 'attendee/theme/Page'
import React from 'react'

export function FullPageLoader() {
  return (
    <Page disableFooter={true}>
      <div id="preloader" style={{position: 'initial', padding: '50vh 0px'}}>
        <Loader />
      </div>
    </Page>
  )
}
