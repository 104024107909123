import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {ImageBlockConfigType} from 'TemplateEditor/Layout/Blocks/ImageBlock/ImageBlock'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'
import {DefaultBlockMobileConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockMobileConfig'

export function ImageBlockMobileConfig() {
  const {handleSubmit, values, setFieldValue} =
    useFormicForm<ImageBlockConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SliderInput
          value={values.mobile.width}
          name={'mobile.width'}
          label="Ширина"
          setFieldValue={setFieldValue}
          max={100}
          min={0}
          step={1}
        />

        <DefaultBlockMobileConfig
          values={values}
          setFieldValue={setFieldValue}
        />
      </form>
    </>
  )
}
