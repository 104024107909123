import {EventListItem} from 'attendee/Tickets/EventListItem'
import {Ticket} from 'attendee/Tickets/Ticket'
import {attendeeApiClient} from 'attendee/apiClient'
import {FullPageLoader} from 'attendee/theme/FullPageLoader'
import Page from 'attendee/theme/Page'
import React, {useEffect, useState} from 'react'

export function Tickets() {
  const {tickets, loading} = useGetTickets()

  if (loading) {
    return <FullPageLoader />
  }

  const tableClass =
    !tickets || tickets?.length === 0
      ? 'attendant__wrapper'
      : 'responsive_table'

  return (
    <Page>
      <section className="event__schedule-area dark_light_class pt-55 pb-150">
        <div className="container">
          <div className="row align-items-center">
            <div className="col-xxl-7 col-xl-7 col-lg-6">
              <h2 className="section__title mb-35">
                Моите <span className="text__highlight">Билети</span>
              </h2>
            </div>

            <div className={`${tableClass} mb-35`}>
              <table>
                <thead>
                  <tr>
                    <th>Номер</th>
                    <th>Цена</th>
                    <th>Събитие</th>
                    <th>Статус</th>
                    <th></th>
                  </tr>
                </thead>
                <tbody>
                  {(!tickets || tickets?.length === 0) && (
                    <tr>
                      <td colSpan={5}>Няма закупени билети</td>
                    </tr>
                  )}
                  {tickets &&
                    tickets.map((ticket) => <EventListItem ticket={ticket} />)}
                </tbody>
              </table>
            </div>
          </div>
        </div>
      </section>
    </Page>
  )
}

function useGetTickets() {
  const apiClient = attendeeApiClient()

  const [tickets, setTickets] = useState<Ticket[] | null>(null)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    async function fetchData() {
      try {
        const response = await apiClient.get(`api/tickets`).finally(() => {
          setLoading(false)
        })
        setTickets(response)
      } catch (error: any) {
        // toast.warning(error.message)
      }
    }
    fetchData()
  }, [])

  return {tickets, loading}
}
