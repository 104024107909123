'use client'
import React from 'react'
import ConfirmationNumberIcon from '@mui/icons-material/ConfirmationNumber'
import {Link} from 'react-router-dom'
import {useEventAttendants} from 'organization/Event/Attendants/EventAttendantsProvider'
import AlternateEmailIcon from '@mui/icons-material/AlternateEmail'
import PersonIcon from '@mui/icons-material/Person'

const TicketSolds = () => {
  const {attendants} = useEventAttendants()

  return (
    <div className="col-xxl-8">
      <div className="card__wrapper">
        {/* Card content */}
        <div className="card__header">
          <div className="card__header-top">
            <div className="card__title-inner">
              <div className="card__header-icon">
                <ConfirmationNumberIcon />
              </div>
              <div className="card__header-title">
                <h4>Последно добавени участници</h4>
              </div>
            </div>
          </div>
        </div>

        <div className="scroll-w-2 card__scrollbar">
          <div className="card__inner">
            {attendants.length === 0 && (
              <div className="tricket__sold-item">Няма добавени участници</div>
            )}

            {attendants.length > 0 &&
              attendants.map((attendant, index) => (
                <div key={index} className="tricket__sold-item">
                  <div className="news__item-inner bb-0">
                    <div className="news__content">
                      <h4 className="news__title">
                        <PersonIcon />{' '}
                        <Link to="#">
                          {' '}
                          {attendant.attendee.first_name}{' '}
                          {attendant.attendee.last_name}
                        </Link>
                      </h4>
                      <div className="news__meta">
                        <div className="news__meta-status">
                          <span>
                            <AlternateEmailIcon />
                          </span>
                          <span> {attendant.attendee.email} </span>
                        </div>
                      </div>
                    </div>
                  </div>

                  <div className="sold__progress-item">
                    <div className="sold__bar-top">
                      <span>Билет номер</span>
                      <span className="devider">:</span>
                      <span className="active">{attendant.id}</span>
                    </div>

                    <span className={'status__tag bg-green'}>
                      <span>Активен</span>
                    </span>
                  </div>
                </div>
              ))}
          </div>
        </div>
      </div>
    </div>
  )
}

export default TicketSolds
