import React from 'react'
import {useFormicForm} from 'TemplateEditor/Layout/Blocks'
import {ButtonOneConfigType} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOne'
import {SliderInput} from 'TemplateEditor/Layout/Blocks/ConfigInputs/SliderInput'
import {DefaultBlockMobileConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockMobileConfig'
import {DefaultBlockTopConfig} from 'TemplateEditor/Layout/Blocks/DefaultBlockTopConfig'

export function ButtonOneMobileConfig() {
  const {handleSubmit, values, setFieldValue} =
    useFormicForm<ButtonOneConfigType>()

  return (
    <>
      <form onSubmit={handleSubmit}>
        <button className={'input__btn w-100 mb-20'} type="submit">
          Запазване
        </button>

        <DefaultBlockTopConfig values={values} setFieldValue={setFieldValue} />

        <SliderInput
          value={values.mobile.fontSize}
          name={'mobile.fontSize'}
          label="Големина на текста"
          setFieldValue={setFieldValue}
          max={8}
          min={0.5}
          step={0.1}
        />

        <SliderInput
          value={values.mobile.width}
          name={'mobile.width'}
          label="Размер на бутона"
          setFieldValue={setFieldValue}
          max={100}
          min={1}
          step={1}
        />

        <SliderInput
          value={values.mobile.height}
          name={'mobile.height'}
          label="Височина на бутона"
          setFieldValue={setFieldValue}
          max={200}
          min={1}
          step={1}
        />

        <DefaultBlockMobileConfig
          values={values}
          setFieldValue={setFieldValue}
        />
      </form>
    </>
  )
}
