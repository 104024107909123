import React from 'react'
import styled from 'styled-components'
import {Droppable, Draggable} from 'react-beautiful-dnd'
import {Section} from 'TemplateEditor/template'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {ListBlocks} from 'TemplateEditor/Layout/Blocks'

export function BlockDrop(props: {
  section: Section
  sectionIndex: number
  index: number
}) {
  const {section, sectionIndex, index} = props
  const {editMode, setEditElement, mobileMode} = useEditor()
  const droppableId = `${section.id}.${index}.block`

  const handleSetEditMode = (editKey: string) => {
    if (!editMode) return

    setEditElement(editKey)
  }

  const hasItems =
    section.blocks && section.blocks[index] && section.blocks[index].length > 0

  const Items = React.memo(() => (
    <>
      {section.blocks &&
        section.blocks[index] &&
        section.blocks[index].map((block, blockIndex) => (
          <Draggable
            key={blockIndex}
            draggableId={block.id}
            index={blockIndex}
            isDragDisabled={!editMode}
          >
            {(provided) => {
              const editKey = `sections.${sectionIndex}.blocks.${index}.${blockIndex}`

              return (
                <div
                  {...provided.draggableProps}
                  {...provided.dragHandleProps}
                  ref={provided.innerRef}
                  key={blockIndex}
                >
                  {ListBlocks &&
                    ListBlocks.map((ListBlock) => {
                      if (block.type === ListBlock.typeId) {
                        return ListBlock.component({
                          block: block,
                          section: section,
                          setEditMode: () => {
                            handleSetEditMode(editKey)
                          },
                          editMode: editMode,
                          mobileMode: mobileMode,
                          key: blockIndex,
                        })
                      }

                      return null
                    })}
                </div>
              )
            }}
          </Draggable>
        ))}
    </>
  ))

  return (
    <Droppable
      droppableId={droppableId}
      type={'block'}
      isDropDisabled={!editMode}
    >
      {(provided, snapshot) => {
        return (
          <BlockDropHolder
            {...provided.droppableProps}
            ref={provided.innerRef}
            $isDraggingOver={snapshot.isDraggingOver}
            $editMode={editMode}
            $hasItems={hasItems}
          >
            <Items />

            {provided.placeholder}
          </BlockDropHolder>
        )
      }}
    </Droppable>
  )
}

const BlockDropHolder = styled.div<{
  $isDraggingOver: boolean
  $editMode: boolean
  $hasItems: boolean
}>`
  min-height: ${(props) =>
    props.$editMode && !props.$hasItems ? '40px' : '0px'};
  height: 100%;

  border-right: ${(props) => (props.$editMode ? '1px grey dashed' : '')};
  border-left: ${(props) => (props.$editMode ? '1px grey dashed' : '')};
  background: ${(props) =>
    props.$isDraggingOver
      ? '#ffdbdb'
      : !props.$hasItems && props.$editMode
        ? 'repeating-linear-gradient(135deg, #ffe4e4 0%, #ffe4e4 1%, transparent 1%, transparent 2%)'
        : 'inherit'};
`
