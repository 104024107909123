import {publicImageUrl} from 'organization/ImageSelector'
import React from 'react'
import styled from 'styled-components'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {Section} from 'TemplateEditor/template'

export function ResponsiveSection(props: {
  children: React.ReactElement
  width: number
  sectionIndex: number
  index: number
  section: Section
}) {
  const {width, sectionIndex, index, children, section} = props
  const {editMode, setEditElement} = useEditor()

  const handleSetEditMode = () => {
    if (!editMode) return

    setEditElement(`sections.${sectionIndex}.innerStyles.${index}`)
  }

  return (
    <Responsive width={width} section={section} index={index}>
      {editMode && (
        <EditButton onClick={handleSetEditMode}>
          <i className="fa-light fa-edit"></i>
        </EditButton>
      )}
      {children}
    </Responsive>
  )
}

const EditButton = styled.div`
  position: absolute;
  background: #03a5ef;
  padding: 0px 10px;
  color: white;
  display: none !important;
  cursor: pointer;
  left: 0;
  font-size: 18px;
  border-bottom-right-radius: 4px;
`

const Responsive = styled.div<{width: number; section: Section; index: number}>`
  & > div {
    display: flex;
    flex-flow: column;
    justify-content: ${(props) =>
      props.section.innerStyles[props.index].position.vertical};
  }

  & > div > div {
    width: 100%;
  }

  & > div > div > div {
    justify-content: ${(props) =>
      props.section.innerStyles[props.index].position.horizontal};
  }

  padding: ${(props) => props.section.innerStyles[props.index].padding.top}px
    ${(props) => props.section.innerStyles[props.index].padding.right}px
    ${(props) => props.section.innerStyles[props.index].padding.bottom}px
    ${(props) => props.section.innerStyles[props.index].padding.left}px;
  background: ${(props) =>
    props.section.innerStyles[props.index].background ?? '#fffff'};

  background-image: url(${(props) =>
    props.section.innerStyles[props.index].backgroundImage
      ? publicImageUrl(props.section.innerStyles[props.index].backgroundImage)
      : ''});
  background-position: center;
  background-size: cover;

  border-color: ${(props) =>
    props.section.innerStyles[props.index].border.color ?? '#ffffff'};
  border-width: ${(props) =>
    props.section.innerStyles[props.index].border.width}px;
  border-radius: ${(props) =>
    props.section.innerStyles[props.index].border.radius}px;
  border-style: ${(props) =>
    props.section.innerStyles[props.index].border.width ? 'solid' : 'initial'};

  width: ${(props) => props.width}%;
  position: relative;
  @container (max-width: 991px) {
    width: 100%;
  }

  &:hover ${EditButton} {
    display: inline !important;
  }
`
