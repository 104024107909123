import React, {useEffect} from 'react'
import {useNavigate} from 'react-router-dom'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {routes} from 'routes/routes'
import {useQueryParams} from 'utils/lib/url'
import {RequestReset} from 'attendee/Auth/PasswordRecover/RequestReset'
import {ResetPassword} from 'attendee/Auth/PasswordRecover/ResetPassword'

export function PasswordRecover() {
  const {token} = useQueryParams()

  const navigate = useNavigate()
  const {user} = useAuth()

  useEffect(() => {
    if (user) {
      navigate(routes.index)
    }
  }, [navigate, user])

  if (token) {
    return <ResetPassword />
  }

  return <RequestReset />
}
