import React from 'react'
import {Image, publicImageUrl} from 'organization/ImageSelector'
import {useImages} from 'organization/ImageSelector/ImagesProvider'
import {BasicLoader} from 'organization/theme/BasicLoader'

export function ImagesList(props: {onSelect: (image: Image) => void}) {
  const {images, remove, save, loading} = useImages()

  if (loading) {
    return <BasicLoader />
  }

  return (
    <div
      className="event__update-file"
      style={{gridTemplateColumns: 'repeat(5, 1fr)'}}
    >
      <div className="event__update-thumb" style={{height: '100%'}}>
        <div
          className="box__input"
          style={{
            background: 'var(--clr-gradient-14)',
            borderRadius: '6px',
            justifyContent: 'center',
            display: 'flex',
            alignItems: 'center',
          }}
        >
          <input
            type="file"
            name="files[]"
            id="file"
            className="box__file"
            data-multiple-caption="{count} files selected"
            multiple
            onChange={(e) => {
              if (e.currentTarget.files) {
                save(e.currentTarget.files)
              }
            }}
          />
          <label
            className="input__field-text"
            htmlFor="file"
            style={{
              height: '100%',
              justifyContent: 'center',
              display: 'flex',
              alignItems: 'center',
            }}
          >
            <span style={{color: '#fff'}}>
              <i className="fa-regular fa-plus"></i>
            </span>
            <span style={{color: '#fff'}}>Добави изображение</span>
          </label>
        </div>
      </div>

      {images &&
        images.map((image) => (
          <div className="event__update-thumb">
            <img
              src={publicImageUrl(image.url)}
              style={{width: '100%', height: 'auto', cursor: 'pointer'}}
              alt="pub"
              onClick={() => {
                props.onSelect(image)
              }}
            />
            <span
              className="update__thumb-close"
              onClick={() => {
                remove(image.id)
              }}
            >
              <i className="fa-regular fa-xmark"></i>
            </span>
          </div>
        ))}
    </div>
  )
}
