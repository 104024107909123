import React from 'react'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import CodeIcon from '@mui/icons-material/Code'
import styled from 'styled-components'

export const CodeTypeId = 'block-Code'

export function Code(props: {
  block: CodeConfigType
  section: Section
  setEditMode: () => void
  key: string | number
  editMode: boolean
}) {
  const {block, setEditMode, editMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <>
        {editMode && <PreviewCode />}
        {!editMode && (
          <StyledCode
            block={block}
            dangerouslySetInnerHTML={{__html: block.value}}
          />
        )}
      </>
    </DefaultBlockHolder>
  )
}

export function PreviewCode() {
  return (
    <PreviewHolderDiv>
      <CodeIcon />
      <span>Код</span>
    </PreviewHolderDiv>
  )
}

export type CodeConfigType = Block &
  DefaultBlockConfigType & {
    value: string
  }

export function newCode(): CodeConfigType {
  return {
    ...DefaultBlockConfigValues(),
    id: uuidv4(),
    type: CodeTypeId,
    value: '<b>HTML / CSS / JS</b>',
  }
}

const StyledCode = styled.div<{block: CodeConfigType}>`
  border-radius: ${(props) => props.block.border.radius}px;
  border-width: ${(props) => props.block.border.width}px;
  border-color: ${(props) => props.block.border.color};
  border-style: solid;
`
