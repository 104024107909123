import React, {ReactElement} from 'react'
import styled from 'styled-components'
export function FAQ() {
  return (
    <div className="accordion" id="accordionFAQ">
      <FAQItem
        id={'q2'}
        open={true}
        question={'Как да купя билет?'}
        answer={
          <span>
            За да купите билет е необходимо да намерите съответното събитие,
            след това да натиснете бутона “Купи билет”. Следващата стъпка е да
            се добави начин на плащане, тук начинът на плащане все още не се
            таксува. След това изберете съответния начин на плащане, тук се
            таксува, след което ще бъдете пренасочен към вашите билети.
          </span>
        }
      />

      <FAQItem
        id={'q1'}
        open={false}
        question={'Кога мога да се присъединя към събитието?'}
        answer={
          <span>
            Можете да се присъедините към събитието на посочените дата и час,
            след като организатора стартира стаята.
          </span>
        }
      />

      <FAQItem
        id={'q4'}
        open={false}
        question={'Кога и как мога да видя страницата на събитието?'}
        answer={
          <span>
            Страницата ще бъде видима веднага след натискането на бутона "Вход"
            и ще остане видима по всяко време.
          </span>
        }
      />

      <FAQItem
        id={'q3'}
        open={false}
        question={'Мога ли да се включа от много устройства едновременно?'}
        answer={
          <span>
            Не, всяко устройство или участник трябва да имат отделен билет.
          </span>
        }
      />

      <FAQItem
        id={'q5'}
        open={false}
        question={'Какво да направя, ако имам технически проблеми?'}
        answer={
          <span>
            Моля, използвайте секцията по-долу, за да се свържете с нас.
          </span>
        }
      />
    </div>
  )
}

export function FAQItem(props: {
  id: string // something unique
  question: string
  answer: ReactElement
  open?: boolean
}) {
  const {id, question, answer, open} = props

  return (
    <StyledItem className="accordion-item">
      <h2 className="accordion-header">
        <span
          className={`accordion-button ${open ? '' : 'collapsed'}`}
          data-bs-toggle="collapse"
          data-bs-target={`#${id}`}
          aria-expanded="false"
          aria-controls={id}
        >
          <span className="accordion__heading">{question}</span>
        </span>
      </h2>
      <div
        id={id}
        className={`accordion-collapse collapse ${open ? 'show' : ''}`}
        data-bs-parent="#accordionFAQ"
      >
        <div className="accordion-body">
          <div className="accordiong__inner">
            <div className="accordion__title-inner mb-25">
              <p>{answer}</p>
            </div>
          </div>
        </div>
      </div>
    </StyledItem>
  )
}

const StyledItem = styled.div`
  & > .accordion-header .accordion-button {
    background: #f5f5f5;
  }

  & .accordion-button:not(.collapsed) {
    background: var(--clr-gradient-14);
    color: #ffffff;
    font-weight: bold;
  }

  & .accordion-body {
    background: #f5f5f5;
  }

  margin-bottom: 20px;
  border: none;
`
