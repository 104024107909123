import React from 'react'
import {InputGroup} from 'react-bootstrap'
import Form from 'react-bootstrap/Form'
import styled from 'styled-components'
import {Padding} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function PaddingInput(props: {
  value: Padding
  setFieldValue: (field: string, value: Padding) => void
  label: string
  name: string
  max: number
  min: number
}) {
  const {value, setFieldValue, name, max, min} = props

  const handleOnTopChange = (newValue: number) => {
    setFieldValue(name, {...value, top: newValue})
  }

  const handleOnBottomChange = (newValue: number) => {
    setFieldValue(name, {...value, bottom: newValue})
  }

  const handleOnLeftChange = (newValue: number) => {
    setFieldValue(name, {...value, left: newValue})
  }

  const handleOnRightChange = (newValue: number) => {
    setFieldValue(name, {...value, right: newValue})
  }

  return (
    <>
      <Form.Group className="mb-3 pb-2">
        {/* <Form.Label>{props.label}</Form.Label> */}

        <InputGroup>
          <StyledInputText style={{borderTopLeftRadius: 12}}>
            Горе
          </StyledInputText>
          <Form.Control
            type="number"
            max={max}
            min={min}
            style={{borderRadius: 0, borderTopRightRadius: 12}}
            value={value.top}
            onChange={(e) => {
              handleOnTopChange(Number(e.target.value))
            }}
          />
        </InputGroup>
        <InputGroup>
          <StyledInputText>Ляво</StyledInputText>
          <Form.Control
            max={max}
            min={min}
            type="number"
            value={value.left}
            onChange={(e) => {
              handleOnLeftChange(Number(e.target.value))
            }}
          />
          <Form.Control
            max={max}
            min={min}
            type="number"
            value={value.right}
            onChange={(e) => {
              handleOnRightChange(Number(e.target.value))
            }}
          />
          <StyledInputText>Дясно</StyledInputText>
        </InputGroup>
        <InputGroup>
          <Form.Control
            type="number"
            max={max}
            min={min}
            style={{borderRadius: 0, borderBottomLeftRadius: 12}}
            value={value.bottom}
            onChange={(e) => {
              handleOnBottomChange(Number(e.target.value))
            }}
          />
          <StyledInputText style={{borderBottomRightRadius: 12}}>
            Долу
          </StyledInputText>
        </InputGroup>
      </Form.Group>
    </>
  )
}

const StyledInputText = styled(InputGroup.Text)`
  border-radius: 0;
  color: var(--clr-common-white);
  background: var(--clr-gradient-1);
  border-width: 0;
  width: 60px;
`
