import React from 'react'
import {BlockDrop} from 'TemplateEditor/Layout/Sections/BlockDrop'
import {DefaultSectionHolder} from 'TemplateEditor/Layout/Sections/DefaultSectionHolder'
import {ResponsiveSection} from 'TemplateEditor/Layout/Sections/ResponsiveSection'
import {
  defaultSectionConfig,
  SectionProps,
} from 'TemplateEditor/Layout/Sections/Section'
import {subSectionDefauts} from 'TemplateEditor/Layout/Sections/SubSectionConfig'
import {
  PreviewSectionDiv,
  PreviewSectionHolderDiv,
} from 'TemplateEditor/Layout/Sections/Types/Section1'
import {Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'

export const section2by1TypeId = 'section-2-1'

export function Section2by1(props: SectionProps) {
  const {section, sectionIndex, key} = props

  return (
    <DefaultSectionHolder section={section} key={key}>
      <>
        <ResponsiveSection
          width={66.6}
          section={section}
          index={1}
          sectionIndex={sectionIndex}
        >
          <BlockDrop section={section} index={1} sectionIndex={sectionIndex} />
        </ResponsiveSection>
        <ResponsiveSection
          width={33.3}
          section={section}
          index={2}
          sectionIndex={sectionIndex}
        >
          <BlockDrop section={section} index={2} sectionIndex={sectionIndex} />
        </ResponsiveSection>
      </>
    </DefaultSectionHolder>
  )
}

export function PreviewSection2by1() {
  return (
    <PreviewSectionHolderDiv>
      <PreviewSectionDiv style={{width: '66.6%'}}>66.6%</PreviewSectionDiv>
      <PreviewSectionDiv style={{width: '33.3%'}}>33.3%</PreviewSectionDiv>
    </PreviewSectionHolderDiv>
  )
}

export function newSection2by1(): Section {
  return {
    ...defaultSectionConfig(),
    id: uuidv4(),
    type: section2by1TypeId,
    blocks: {},
    innerStyles: {
      1: subSectionDefauts(),
      2: subSectionDefauts(),
    },
  }
}
