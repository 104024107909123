import React from 'react'
import styled from 'styled-components'
import {
  DefaultBlockConfigType,
  DefaultBlockConfigValues,
  TextAlign,
} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'
import {DefaultBlockHolder} from 'TemplateEditor/Layout/Blocks/DefaultBlockHolder'
import {PreviewHolderDiv} from 'TemplateEditor/Layout/Sidebar/BlocksList'
import {Block, Section} from 'TemplateEditor/template'
import {v4 as uuidv4} from 'uuid'
import TextFieldsIcon from '@mui/icons-material/TextFields'

export const NormalTextTypeId = 'block-normal-text'

export function NormalText(props: {
  block: NormalTextConfigType
  section: Section
  setEditMode: () => void
  key: string | number
}) {
  const {block, setEditMode} = props

  return (
    <DefaultBlockHolder block={block} key={props.key} onClick={setEditMode}>
      <>
        {(!block.value || block.value === '<p><br></p>') && (
          <PreviewNormalText />
        )}
        {block.value && block.value !== '<p><br></p>' && (
          <EditorText
            block={block}
            dangerouslySetInnerHTML={{__html: block.value}}
          />
        )}
      </>
    </DefaultBlockHolder>
  )
}

export function PreviewNormalText() {
  return (
    <PreviewHolderDiv>
      <TextFieldsIcon />
      <span>Текст</span>
    </PreviewHolderDiv>
  )
}

export type NormalTextConfigType = Block &
  DefaultBlockConfigType & {
    fontColor: string
    fontBackground: string
    font: string
    fontSize: number
    value: string
    align: TextAlign
    mobile: {
      fontSize: number
      align: TextAlign
    }
  }

export function newNormalText(): NormalTextConfigType {
  return {
    ...DefaultBlockConfigValues(),
    id: uuidv4(),
    type: NormalTextTypeId,
    fontColor: '#333333',
    fontBackground: '',
    value: '',
    fontSize: 1.5,
    font: 'Comfortaa',
    align: 'left',
    mobile: {
      ...DefaultBlockConfigValues().mobile,
      fontSize: 1.5,
      align: 'left',
    },
  }
}

const EditorText = styled.div<{block: NormalTextConfigType}>`
  border-radius: ${(props) => props.block.border.radius}px;
  border-width: ${(props) => props.block.border.width}px;
  border-color: ${(props) => props.block.border.color};
  border-style: solid;

  & > p {
    font-family: ${(props) => props.block.font};
    color: ${(props) => props.block.fontColor};
    font-size: ${(props) => props.block.fontSize}rem;
    line-height: normal;
    padding: 0;
    margin: 0;
    background: ${(props) =>
      props.block.fontBackground !== ''
        ? props.block.fontBackground
        : 'transparent'};
    text-align: ${(props) => props.block.align};
  }

  @container (max-width: 991px) {
    & > p {
      font-size: ${(props) => props.block.mobile.fontSize}rem;
      text-align: ${(props) => props.block.mobile.align};
    }
  }
`
