import {useFormik} from 'formik'
import {ReactElement, useEffect} from 'react'
import {useEditor} from 'TemplateEditor/EditorProvider'
import {
  ButtonOne,
  ButtonOneTypeId,
  PreviewButtonOne,
  newButtonOne,
  ButtonOneConfigType,
} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOne'
import {ButtonOneConfig} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOneConfig'
import {ButtonOneMobileConfig} from 'TemplateEditor/Layout/Blocks/ButtonOne/ButtonOneMobileConfig'
import {
  Code,
  CodeConfigType,
  CodeTypeId,
  newCode,
  PreviewCode,
} from 'TemplateEditor/Layout/Blocks/Code/Code'
import {CodeConfig} from 'TemplateEditor/Layout/Blocks/Code/CodeConfig'
import {
  ImageBlock,
  ImageBlockConfigType,
  ImageBlockTypeId,
  newImageBlock,
  PreviewImageBlock,
} from 'TemplateEditor/Layout/Blocks/ImageBlock/ImageBlock'
import {ImageBlockConfig} from 'TemplateEditor/Layout/Blocks/ImageBlock/ImageBlockConfig'
import {ImageBlockMobileConfig} from 'TemplateEditor/Layout/Blocks/ImageBlock/ImageBlockMobileConfig'
import {
  newSpacer,
  PreviewSpacer,
  Spacer,
  SpacerConfigType,
  SpacerTypeId,
} from 'TemplateEditor/Layout/Blocks/Spacer/Spacer'
import {SpacerConfig} from 'TemplateEditor/Layout/Blocks/Spacer/SpacerConfig'
import {SpacerMobileConfig} from 'TemplateEditor/Layout/Blocks/Spacer/SpacerMobileConfig'
import {
  newNormalText,
  NormalText,
  NormalTextConfigType,
  NormalTextTypeId,
  PreviewNormalText,
} from 'TemplateEditor/Layout/Blocks/Text/NormalText'
import {NormalTextConfig} from 'TemplateEditor/Layout/Blocks/Text/NormalTextConfig'
import {NormalTextMobileConfig} from 'TemplateEditor/Layout/Blocks/Text/NormalTextMobileConfig'
import {
  newVideo,
  PreviewVideo,
  Video,
  VideoConfigType,
  VideoTypeId,
} from 'TemplateEditor/Layout/Blocks/Video/Video'
import {VideoConfig} from 'TemplateEditor/Layout/Blocks/Video/VideoConfig'
import {VideoMobileConfig} from 'TemplateEditor/Layout/Blocks/Video/VideoMobileConfig'
import {SectionConfigType} from 'TemplateEditor/Layout/Sections/SectionConfig'
import {SubSectionConfigType} from 'TemplateEditor/Layout/Sections/SubSectionConfig'
import {Block} from 'TemplateEditor/template'
import {useTemplate} from 'TemplateEditor/TemplateProvider'

import {getByNestedKeys} from 'utils/lib/json'

export type ListBlockType = {
  typeId: string
  component: (props: any) => ReactElement
  preview: () => ReactElement
  config: () => ReactElement
  mobileConfig: () => ReactElement
  new: () => BlockElements
}

export type BlockElements =
  | NormalTextConfigType
  | ButtonOneConfigType
  | CodeConfigType
  | ImageBlockConfigType
  | SpacerConfigType
  | VideoConfigType

export const ListBlocks: ListBlockType[] = [
  {
    typeId: ButtonOneTypeId,
    component: ButtonOne,
    preview: PreviewButtonOne,
    new: newButtonOne,
    config: ButtonOneConfig,
    mobileConfig: ButtonOneMobileConfig,
  },
  {
    typeId: NormalTextTypeId,
    component: NormalText,
    preview: PreviewNormalText,
    new: newNormalText,
    config: NormalTextConfig,
    mobileConfig: NormalTextMobileConfig,
  },
  {
    typeId: ImageBlockTypeId,
    component: ImageBlock,
    preview: PreviewImageBlock,
    new: newImageBlock,
    config: ImageBlockConfig,
    mobileConfig: ImageBlockMobileConfig,
  },
  {
    typeId: SpacerTypeId,
    component: Spacer,
    preview: PreviewSpacer,
    new: newSpacer,
    config: SpacerConfig,
    mobileConfig: SpacerMobileConfig,
  },
  {
    typeId: VideoTypeId,
    component: Video,
    preview: PreviewVideo,
    new: newVideo,
    config: VideoConfig,
    mobileConfig: VideoMobileConfig,
  },
  {
    typeId: CodeTypeId,
    component: Code,
    preview: PreviewCode,
    new: newCode,
    config: CodeConfig,
    mobileConfig: CodeConfig,
  },
]

export const useFormicForm = <
  T extends BlockElements | SectionConfigType | SubSectionConfigType,
>(
  submitUpdateValuesHandler?: <T>(
    values: Omit<T, keyof Block>,
    block: T,
  ) => void,
  changeUpdateValuesHandler?: <T>(
    values: Omit<T, keyof Block>,
    block: T,
  ) => void,
) => {
  const {template} = useTemplate()

  const {editElement, updateElement, setElement} = useEditor()
  let block: T = getByNestedKeys(template, editElement)

  const formic = useFormik({
    initialValues: block,
    enableReinitialize: true,
    onSubmit: async (values: Omit<T, keyof Block>) => {
      if (submitUpdateValuesHandler) {
        submitUpdateValuesHandler<T>(values, block)
      } else {
        defaultSubmitUpdateValuesHandler<T>(values, block)
      }

      updateElement(block)
    },
  })

  const handleChangeForm = () => {
    if (changeUpdateValuesHandler) {
      changeUpdateValuesHandler<T>(formic.values, block)
    } else if (submitUpdateValuesHandler) {
      submitUpdateValuesHandler<T>(formic.values, block)
    } else {
      defaultSubmitUpdateValuesHandler<T>(formic.values, block)
    }

    setElement(block)
  }

  useEffect(() => {
    handleChangeForm()
  }, [formic.values])

  return formic
}

const defaultSubmitUpdateValuesHandler = <T,>(
  values: Omit<T, keyof Block>,
  block: T,
) => {
  if (!values) return

  Object.keys(values).forEach((k) => {
    const key = k as keyof typeof values
    block[key] = values[key]
  })
}
