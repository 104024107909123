import React, {useEffect} from 'react'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import {useQueryParams} from 'utils/lib/url'
import {routes} from 'routes/routes'
import {organizationAccessTokenkey} from 'organization/organizationApiClient'

export function OrganizationAutologin() {
  const {token} = useQueryParams()

  useEffect(() => {
    localStorage.setItem(organizationAccessTokenkey, token as any)
    window.location.href = routes.organization.list
  }, [token])

  return <FullPageLoader />
}
