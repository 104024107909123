import React from 'react'
import Form from 'react-bootstrap/Form'
import {TextAlign} from 'TemplateEditor/Layout/Blocks/DefaultBlockConfig'

export function TextAlignInput(props: {
  value: TextAlign
  setFieldValue: (field: string, value: TextAlign) => void
  label: string
  name: string
}) {
  const {value, setFieldValue, name} = props

  const handleOnChange = (value: TextAlign) => {
    setFieldValue(name, value)
  }

  return (
    <Form.Group className="mb-3 pb-2">
      <Form.Label>{props.label}</Form.Label>
      <Form.Select
        onChange={(e) => {
          handleOnChange(e.target.value as TextAlign)
        }}
        className="form-control"
        value={value}
      >
        <option value="left">Ляво</option>
        <option value="center">Централно</option>
        <option value="justify">Разтеглено</option>
        <option value="right">Дясно</option>
      </Form.Select>
    </Form.Group>
  )
}
