import {useOrganizationEvent} from 'organization/Event/OrganizationEventProvider'
import {organizationApiClient} from 'organization/organizationApiClient'
import {FullPageLoader} from 'organization/theme/FullPageLoader'
import React, {useEffect, useState} from 'react'
import {toast} from 'react-toastify'

type Image = {
  id: number
  url: string
}

export interface ImagesContextProps {
  images: Image[]
  save: (images: FileList) => Promise<boolean | void>
  remove: (imageId: Image['id']) => Promise<boolean | void>
  loading: boolean
}

export const ImagesContext = React.createContext<
  ImagesContextProps | undefined
>(undefined)

export default function ImagesProvider(props: {children: React.ReactElement}) {
  const apiClient = organizationApiClient()
  const {event} = useOrganizationEvent()
  const [images, setImages] = useState<Image[]>([])
  const [initLoading, setInitLoading] = useState<boolean>(true)
  const [loading, setLoading] = useState<boolean>(true)

  useEffect(() => {
    setInitLoading(true)
    get()
  }, [])

  const get = async () => {
    setLoading(true)
    await apiClient
      .get(`api/event/${event?.id}/images`)
      .then((res) => setImages(res))
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
      .finally(() => {
        setLoading(false)
        setInitLoading(false)
      })
  }

  const remove = async (imageId: Image['id']) => {
    if (loading) return

    setLoading(true)
    return apiClient
      .post(`api/event/${event?.id}/image/${imageId}`)
      .then(get)
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
      .finally(() => setLoading(false))
  }

  const save = async (images: FileList) => {
    if (loading) return

    setLoading(true)

    for (const image of images) {
      await uploadFile(image)
    }

    get()
  }

  const uploadFile = async (image: File) => {
    const formData = new FormData()
    formData.append('file', image)

    return apiClient
      .post(`api/event/${event?.id}/image`, formData, {
        headers: {
          'content-type': 'multipart/form-data',
        },
      })
      .catch((error) => {
        toast.warning(error.response.data.message)
        return false
      })
  }

  return (
    <ImagesContext.Provider
      value={{
        images,
        remove,
        save,
        loading,
      }}
    >
      {initLoading && <FullPageLoader />}
      {!initLoading && props.children}
    </ImagesContext.Provider>
  )
}

export function useImages() {
  const context = React.useContext(ImagesContext)
  if (context === undefined) {
    throw new Error('useImages must be used within ImagesProvider')
  }
  return context
}
