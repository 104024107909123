import React, {useEffect} from 'react'
import {toast} from 'react-toastify'
import {useLocation, useNavigate} from 'react-router-dom'
import {useAuth} from 'attendee/Auth/Providers/AuthProvider'
import {routes} from 'routes/routes'
import {FullPageLoader} from 'attendee/theme/FullPageLoader'

export function GoogleCallback() {
  const {googleCallback} = useAuth()
  const location = useLocation()
  const navigate = useNavigate()

  const acceptCallback = async () => {
    const result = await googleCallback(location.search)

    if (result === true) {
      toast.success('Успешен вход')
    }

    navigate(routes.index)
  }

  useEffect(() => {
    acceptCallback()
  }, [])

  return <FullPageLoader />
}
