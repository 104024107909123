import React from 'react'
import ReactQuill, {Quill} from 'react-quill'
import ImageCompress from 'quill-image-compress'
import 'react-quill/dist/quill.snow.css'
import styled from 'styled-components'

export default function TextEditor(props: {
  value: string
  onChange: (value: string) => void
  whiteMode?: boolean
  fullModules?: boolean
}) {
  const {value, onChange, fullModules} = props

  const toolbar = fullModules
    ? {
        container: [
          [{font: [false]}, {size: ['small', false, 'large', 'huge']}],
          [
            'bold',
            'italic',
            'underline',
            'strike',
            {color: []},
            {background: []},
          ],
          [
            {list: 'ordered'},
            {list: 'bullet'},
            {indent: '-1'},
            {indent: '+1'},
            {align: []},
          ],
          ['blockquote', 'link', 'image', 'clean'],
        ],
      }
    : {
        container: [['bold', 'italic', 'underline', 'strike', 'clean']],
      }

  const modules = {
    toolbar: toolbar,
    clipboard: {
      // toggle to add extra line breaks when pasting HTML:
      matchVisual: false,
    },
    imageCompress: {
      quality: 0.7, // default
      maxWidth: 1000, // default
      maxHeight: 1000, // default
      imageType: 'image/jpeg', // default
      debug: false, // default
      suppressErrorLogging: false, // default
      handleOnPaste: true, //default
      insertIntoEditor: undefined, // default
    },
  }

  Quill.register('modules/imageCompress', ImageCompress)

  if (props.whiteMode) {
    return (
      <StyledReactQuill value={value} onChange={onChange} modules={modules} />
    )
  }

  return <BaseReactQuill value={value} onChange={onChange} modules={modules} />
}

const StyledReactQuill = styled(ReactQuill)`
  & > .ql-formats svg {
    vertical-align: baseline;
  }
  & > .ql-toolbar .ql-formats button svg path {
    fill: #ffffff;
  }

  & > .ql-snow .ql-picker-label::before {
    color: #ffffff;
  }

  & > .ql-snow .ql-stroke {
    stroke: #ffffff;
  }

  & > .ql-snow .ql-editor {
    background: #ffffff;
  }

  & > .ql-toolbar {
    border-top-left-radius: 12px;
    border-top-right-radius: 12px;
  }

  & > .ql-container {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  & > .ql-container .ql-editor {
    border-bottom-left-radius: 12px;
    border-bottom-right-radius: 12px;
  }

  border-radius: 12px;
`

const BaseReactQuill = styled(ReactQuill)`
  & > .ql-toolbar.ql-snow .ql-formats svg {
    vertical-align: baseline;
  }
`
